const styles = {
  appBar: (theme) => ({
    background: 'transparent',
    boxShadow: "none",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    [theme.breakpoints.up("md")]: {
      margin: "auto",
    },
  }),
  logoDesk: {
    mr: 2,
    display: { xs: "none", lg: "flex" },
    flexGrow: 1,
  },
  logoMob: {
    mr: 2,
    display: { xs: "flex", lg: "none" },
    flexGrow: 1,
    justifyContent: "center"
  },
  buttons: (theme) => ({
    flexDirection: "row",
    display: "flex",
    gap: 2,
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      flexDirection: "column",
      gap: 2,
      marginTop: "5px"
    },
  }),
  buttonFeilds: (theme) => ({
    background: "#FFFFFF",
    borderRadius: "8px",
    border: 1,
    borderColor: "#fff",
    color: "#000000",
    width: "136.5px",
    height: "38.53px",
    "&:hover": {
      color: "#FFFFFF",
      borderColor: "#000000",
      border: 1
    },
    [theme.breakpoints.down("md")]: {
      width: "50px",
      height: "25px"
    },
  }),
  dashboardButton: (theme) => ({
    background: "#FFFFFF",
    borderRadius: "8px",
    border: 1,
    borderColor: "#fff",
    color: "#000000",
    "&:hover": {
      color: "#FFFFFF",
      borderColor: "#000000",
      border: 1
    },
    [theme.breakpoints.down("md")]: {

      fontSize: "8px !important"
    },
  })
};

export default styles
