import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from "./components/Pages/Home";
import Dashboard from "./components/Pages/Dashboard";
import Login from "./components/Pages/Login";
import Otp from "./components/Pages/OTP";
import Signup from "./components/Pages/Signup";
import ResetPassword from "./components/Pages/ResetPassword";
import ForgotPassword from "./components/Pages/ForgotPassword";
import ColdEmail from "./components/Pages/ColdEmail";
import LinkedinEmail from "./components/Pages/LinkedinEmail";
import CSVUpload from "./components/Pages/CSVUpload";
import Pricing from "./components/Pages/Pricing";
import NotFound from "./components/Pages/NotFound";
import Subscription from "./components/Pages/Subscription";
import AboutUs from "./components/Pages/AboutUs";

import { CustomAuth, RequireAuth, WithoutAuth } from "./components/Helper/RequiredAuth";
import Blog from "./components/Pages/Blog";


export interface RootState {
  token: string;
  object: Record<string, unknown>;
}

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/reset_password" element={<WithoutAuth><ResetPassword /></WithoutAuth>} />
        <Route path="/forgotPassword" element={<WithoutAuth><ForgotPassword /></WithoutAuth>} />
        <Route path="/login" element={<WithoutAuth><Login /></WithoutAuth>} />
        <Route path="/signup" element={<WithoutAuth><Signup /> </WithoutAuth>} />
        <Route path="/verifyOtp" element={<CustomAuth><Otp /></CustomAuth>} />
        <Route path="/dashboard" element={<RequireAuth><Dashboard /></RequireAuth>} />
        <Route path="/coldEmail" element={<RequireAuth><ColdEmail /></RequireAuth>} />
        <Route path="/linkedinEmail" element={<RequireAuth><LinkedinEmail /></RequireAuth>} />
        <Route path="/csvUpload" element={<RequireAuth><CSVUpload /></RequireAuth>} />
        <Route path="/subscription" element={<RequireAuth><Subscription /></RequireAuth>} />

        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
