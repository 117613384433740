
const styles = {
  paragraph: (theme) => ({
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: '400 !important',
    fontSize: "14px !important",
    color: "#000000",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      fontSize: "10px !important",
    },
  }),
  boxes: (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    gap: 5,
    padding: 1,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      justifyContent: 'center',
    },
  }
  ),
  subBoxes: (theme) => ({
    border: "0.734472px solid #9804D3",
    background: "#fff",
    margin: "auto",
    padding: 5,
    // height: 350,
    // width: "max-content",
    [theme.breakpoints.down("md")]: {
      height: "100%"
    },
  }),
  selectedSubBoxes: (theme) => ({
    border: "0.734472px solid #9804D3",
    background: "#9804D3",
    margin: "auto",
    padding: 5,
    // height: 350,
    width: "max-content",
    [theme.breakpoints.down("md")]: {
      height: "100%"
    },
  }),
  subHeading: (theme) => ({
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: '200 !important',
    fontSize: "20px !important",
    color: "rgba(0, 0, 0, 0.9)",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px !important",
    },
  }),
  price: (theme) => ({
    color: "#9804D3",
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "50px !important",
  }),
  Button: (theme) => ({
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    color: "#9804D3",
    border: "1.47253px solid #9804D3",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
  }),
  selectedSubHeading: (theme) => ({
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: '200 !important',
    fontSize: "24px !important",
    color: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px !important",
    },
  }),
  selectedPrice: (theme) => ({
    color: "#FFFFFF",
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "50px !important",
  }),
  selectedParagraph: (theme) => ({
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: '400 !important',
    fontSize: "14px !important",
    color: "#FFFFFF",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      fontSize: "10px !important",
    },
  }),
  notInOffer: (theme) => ({
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: '400 !important',
    fontSize: "14px !important",
    color: "#000000",
    width: "100%",
    textDecoration: "line-through",
    [theme.breakpoints.down("md")]: {
      fontSize: "10px !important",
    },
  }),
  selectedNotInOffer: (theme) => ({
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: '400 !important',
    fontSize: "14px !important",
    color: "#FFFFFF",
    width: "100%",
    textDecoration: "line-through",
    [theme.breakpoints.down("md")]: {
      fontSize: "10px !important",
    },

  }),
  buttonFeilds: (theme) => ({

    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "20px",
    textAlign: "center",
    background: "linear-gradient(85.78deg, #1D09BD -23.77%, #EB01E3 110.4%)",
    borderRadius: "8px",
    border: 1,
    borderColor: "#fff",
    color: "#fff",
    width: "136.5px",
    height: "38.53px",
    textTransform: "none", marginTop: "20px",
    "&:hover": {
      color: "#1D09BD",
      borderColor: "#EB01E3",
      background: "#FFFFFF",
      border: 1
    },
    [theme.breakpoints.down("md")]: {

      width: "136.5px",
      height: "38.53px",
    },
  }),
}

export default styles
