const styles = {
  container: (theme) => ({
    background: '#FFFFFF',
    margin: 'auto',
    padding: 25,
    [theme.breakpoints.down("md")]: {
      width: "90%",
      padding: 0,
      margin: 0
    },
  }),
  mainHeading: (theme) => ({
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: '400 !important',
    fontSize: "40px !important",
    lineHeight: "48px !important",
    color: "#C85EF1",
    textAlign: 'center',
    marginBottom: "5%",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px !important",
    },
  }),
  paragraph: (theme) => ({
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: '400 !important',
    fontSize: "18px !important",
    lineHeight: "32px !important",
    textAlign: "center",
    color: "#696969",
    [theme.breakpoints.down("md")]: {
      fontSize: "10px !important",
    },
  }),

}

export default styles
