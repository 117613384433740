import { Box } from '@mui/system';
import Footer from '../Footer';
import Header from '../Header';
import styles from './styles'
import ContextSection from './components/ContextSection/ContextSection';

const AboutUs = () => {
  return (
    <Box sx={{ width: '100%', margin: 0 }}>
      <Box sx={styles.block}>
        <Header />
      </Box>

      <ContextSection />
      <Footer />
    </Box >
  );
};

export default AboutUs;
