import { Box } from '@mui/system';
import { useState } from 'react';
import HeaderAfterLogin from '../HeaderAfterLogin';
import Upload from './component/CSVUploadComponent';

const CSVUpload = () => {
  const [open, setOpen] = useState(true)
  const [mobileView, setMobileView] = useState(false)
  return (
    <Box sx={{ width: '100%' }}>
      <HeaderAfterLogin
        Title={"Bulk Import"}
        open={open}
        setOpen={setOpen}
        setMobileView={setMobileView}
        mobileView={mobileView}
        body={<Upload />}
      />
    </Box >
  );
};

export default CSVUpload;

