import LoginComponent from '../Login'

const Login = () => {
  return (
    <div>
      <LoginComponent />
    </div>
  )
}

export default Login
