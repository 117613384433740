const styles = {
  container: (theme) => ({
    display: "flex",
    flexDirection: "row",
    gap: 2,
    width: "250%",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: 2,
      width: "100%"
    },

  }),
  subContainer: (theme) => ({
    width: "50%", background: "#fff",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      width: "100%"
    },
  }),
  h1: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600 !important",
    fontSize: "24px !important",
    lineHeight: "39px",
    color: "#000000",
    marginTop: 3
  },
  h2:
  {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600 !important",
    fontSize: "24px !important",
    lineHeight: "39px",
    color: "#000000",
    marginTop: 3,
    display: "flex",
    alignItem: "center",
    justifyContent: "center"
  },

  button: {
    background: "linear-gradient(266.85deg, #005CF3 -16.58%, rgba(255, 0, 199, 0.52) 179.36%)", color: "#FFFFFF", fontFamily: "'Inter'",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "21.3333px",
    lineHeight: "29px",
    textTransform: 'none',
    marginBottom: 5
  },
  paragraph: {
    fontFamily: "'Inter'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px !important",
    color: "#000000"
  }
}

export default styles
