import { Box, List, ListItem, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import styles from './styles'

const PricingDetailComponent = () => {
  const [subscribedPlan, setSubscribedPlan] = useState({
    free: false,
    basic: false,
    pro: false
  })
  useEffect(() => {
    getPricingDetail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getPricingDetail = async () => {
    setSubscribedPlan({
      ...subscribedPlan,
      free: true
    })
  }
  return (
    <Box>
      <Box sx={styles.boxes}>
        <Box sx={subscribedPlan.free === true ? styles.selectedSubBoxes : styles.subBoxes}>
          <List sx={{ gap: 2, display: "flex", justifyContent: "flex-start", flexDirection: "column", }}>
            <ListItem>
              <Box>
                <Typography sx={subscribedPlan.free === true ? styles.selectedSubHeading : styles.subHeading} >Free</Typography>
                <Stack sx={{ display: "flex", flexDirection: "row", marginTop: 2 }}>
                  <Typography sx={subscribedPlan.free === true ? styles.selectedPrice : styles.price}>$0.00</Typography>
                  <Typography sx={{ fontSize: "10px !important", color: subscribedPlan.free === true ? "#fff" : "#000" }}>/per month</Typography>
                </Stack>
              </Box>
            </ListItem>
            <ListItem sx={subscribedPlan.free === true ? styles.selectedParagraph : styles.paragraph}>5 Emails Per Month</ListItem>
            <ListItem sx={subscribedPlan.free === true ? styles.selectedParagraph : styles.paragraph}>AI Email Generation</ListItem>
            <ListItem sx={subscribedPlan.free === true ? styles.selectedNotInOffer : styles.notInOffer}>CSV Support</ListItem>
            <ListItem sx={subscribedPlan.free === true ? styles.selectedNotInOffer : styles.notInOffer}>LinkedIn email generation</ListItem>
          </List>
        </Box>
        <Box sx={subscribedPlan.basic === true ? styles.selectedSubBoxes : styles.subBoxes}>
          <List sx={{ gap: 2, display: "flex", justifyContent: "flex-start", flexDirection: "column", }}>
            <ListItem>
              <Box>
                <Typography sx={subscribedPlan.basic === true ? styles.selectedSubHeading : styles.subHeading} >Basic</Typography>
                <Stack sx={{ display: "flex", flexDirection: "row", marginTop: 2 }}>
                  <Typography sx={subscribedPlan.basic === true ? styles.selectedPrice : styles.price}>$9.99</Typography>
                  <Typography sx={{ fontSize: "10px !important", color: subscribedPlan.basic === true ? "#fff" : "#000" }}>/per month</Typography>
                </Stack>
              </Box>
            </ListItem>
            <ListItem sx={subscribedPlan.basic === true ? styles.selectedParagraph : styles.paragraph}>400 Emails Per Month</ListItem>
            <ListItem sx={subscribedPlan.basic === true ? styles.selectedParagraph : styles.paragraph}>AI Email Generation</ListItem>
            <ListItem sx={subscribedPlan.basic === true ? styles.selectedParagraph : styles.paragraph}>CSV Support</ListItem>
            <ListItem sx={subscribedPlan.basic === true ? styles.selectedNotInOffer : styles.notInOffer}>LinkedIn email generation</ListItem>
          </List>
        </Box>
        <Box sx={subscribedPlan.pro === true ? styles.selectedSubBoxes : styles.subBoxes}>
          <List sx={{ gap: 2, display: "flex", justifyContent: "flex-start", flexDirection: "column", }}>
            <ListItem>
              <Box>
                <Typography sx={subscribedPlan.pro === true ? styles.selectedSubHeading : styles.subHeading} >Pro</Typography>
                <Stack sx={{ display: "flex", flexDirection: "row", marginTop: 2 }}>
                  <Typography sx={subscribedPlan.pro === true ? styles.selectedPrice : styles.price}> $29.99</Typography>
                  <Typography sx={{ fontSize: "10px !important", color: subscribedPlan.pro === true ? "#fff" : "#000" }}>/per month</Typography>
                </Stack>
              </Box>
            </ListItem>
            <ListItem sx={subscribedPlan.pro === true ? styles.selectedParagraph : styles.paragraph}>2000 Emails Per Month</ListItem>
            <ListItem sx={subscribedPlan.pro === true ? styles.selectedParagraph : styles.paragraph}>AI Email Generation</ListItem>
            <ListItem sx={subscribedPlan.pro === true ? styles.selectedParagraph : styles.paragraph}>CSV Support</ListItem>
            <ListItem sx={subscribedPlan.pro === true ? styles.selectedParagraph : styles.paragraph}>LinkedIn email generation</ListItem>
          </List>
        </Box>
      </Box >
    </Box>
  );
};

export default PricingDetailComponent;
