import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import styles from "./styles";
import CONFIG from "../config";
import axios from "axios";
import { Alert, Card, Link, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { MuiOtpInput } from 'mui-one-time-password-input'
import { useDispatch, useSelector } from "react-redux";
import { setObject } from "../../redux/action";

const FormComponent = () => {
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const [success, setSuccess] = useState(false)
  const [errors, setErrors] = useState({
    verifyOtp: false,
    retryOtp: false
  })
  const handleChange = (enteredOtp) => {

    setErrors({
      verifyOtp: false,
      retryOtp: false
    })
    setSuccess(false)
    setOtp(enteredOtp);
  };

  const Submit = () => {

    axios.post(CONFIG.BASE_URL + "api/registration/verify-otp/", { otp_code: otp }, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
      .then((response) => {
        dispatch(setObject({ emailVerified: true }))
        navigate('/dashboard')
      }).catch((error) => {
        if ('response' in error && 'status' in error.response) {
          if (error.response.status === 401) {
            setErrors({ ...errors, verifyOtp: 'Authentication Error' })
          }
          if ('message' in error.response.data) {
            setErrors({ ...errors, verifyOtp: error.response.data.message })
          }
        } else setErrors({ ...errors, verifyOtp: 'Network Failed, Please try again!' })
      })
  }

  const resendOtp = () => {
    setSuccess(false)
    setErrors({ verifyOtp: false, retryOtp: false })
    axios.post(CONFIG.BASE_URL + "api/registration/resend-otp/", {}, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
      .then((response) => {
        setSuccess(true)
      }).catch((error) => {
        if ('response' in error && 'status' in error.response) {
          if (error.response.status === 401) {
            setErrors({ ...errors, retryOtp: 'Authentication Error' })
          }
          if ('message' in error.response.data) {
            setErrors({ ...errors, retryOtp: error.response.data.message })
          }
        }
        else setErrors({ ...errors, retryOtp: 'Network Failed, Please try again!' })
      })
  }



  const validateChar = (value, index) => {
    let success = !isNaN(value)
    if (!success) setErrors({ ...errors, verifyOtp: 'Only Digits are allowed' })
    return success
  }

  return (

    <Card sx={{
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      gap: "20px",
      padding: "20px"
    }}>
      <Container>
        <MuiOtpInput value={otp} onChange={handleChange} length={6} validateChar={validateChar} />
        {errors.verifyOtp ? <div style={{ color: 'red', marginBottom: 15, alignItems: "center", justifyContent: "center", display: "flex" }}><p>{errors.verifyOtp}</p></div> : null}
      </Container>
      <Container>
        <Container sx={{ display: "flex", justifyContent: "center", }}>
          <Button type={"submit"} sx={styles.verifyButton}
            onClick={Submit}
            disabled={otp.length === 6 ? false : true}>
            Verify Your OTP
          </Button>
        </Container>
        <Container>
          <Typography sx={{ display: "flex", justifyContent: "center", gap: "10px", fontSize: "16px !important" }}> Or <Button onClick={resendOtp} sx={{ fontSize: "16px" }}> Retry </Button></Typography>
          {errors.retryOtp ? <div style={{ color: 'red', marginBottom: 15, alignItems: "center", justifyContent: "center", display: "flex" }}><p>{errors.retryOtp}</p></div> : null}
        </Container>
        <Container>
          {success ? <Alert severity="success">Successfully send</Alert> : null}
        </Container>
      </Container>
      <Container sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
        <Typography sx={{
          fontFamily: 'Inter',
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          color: "#555555"
        }} variant="subtitle1"> By using Closed Won you agree to the <Link href="#" sx={{
          fontFamily: 'Inter',
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "17px",
          textAlign: "center",
        }}>Terms of Service </Link>
          and <Link href="#" sx={{
            fontFamily: 'Inter',
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "17px",
          }}>Privacy Policy </Link></Typography>
      </Container>
    </Card >
  )
};

export default FormComponent;
