import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import styles from "./styles";
import { IconButton, InputAdornment, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Container } from "@mui/system";
import axios from "axios";
import CONFIG from "../config";
import AlertDialogSlide from "../Helper/AnimatedButton";


const validationSchema = yup.object({
  password: yup.string()
    .required('No password provided.')
    .min(8, 'Password must be 8 characters long')
    .matches(/[0-9]/, 'Password requires a number')
    .matches(/[a-z]/, 'Password requires a lowercase letter')
    .matches(/[A-Z]/, 'Password requires an uppercase letter')
    .matches(/[^\w]/, 'Password requires a symbol'),
  confirmPassword: yup.string()
    .oneOf([yup.ref('password'), null], 'Must match "password" field value'),
});



const FormComponent = () => {

  // const navigate = useNavigate();
  const [errors, setErrors] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [open, setOpen] = useState(false)
  const [token, setToken] = useState("")

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const myParam = params.get('id');
    setToken(myParam)
  }, []);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      var formdata = new FormData();
      formdata.append("password", values.password);
      formdata.append("confirm_password", values.confirmPassword);
      formdata.append("token", token)

      axios.post(CONFIG.BASE_URL + "api/registration/password-reset-confirm/", formdata)
        .then((response) => {
          setOpen(true)
          resetForm()
        }).catch((error) => {
          setErrors(true)
        })

    },
  });
  const handleOnChange = (event) => {
    setErrors(false)
  };

  return (
    <form onSubmit={formik.handleSubmit} onChange={handleOnChange} >
      <Container sx={styles.formContainer}>
        <Box sx={styles.inputs}>
          <Typography variant="h1" sx={styles.h1}> Reset Password </Typography>
          <TextField
            placeholder="Password"
            id="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={
              formik.touched.password && formik.errors.password
                ? formik.touched.password && formik.errors.password
                : " "
            }
            sx={styles.textField}
            fullWidth
            type={showPassword ? "text" : "password"}
            InputProps={{ // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <TextField
            placeholder="Confirm Password"
            id="confirmPassword"
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
                ? formik.touched.confirmPassword && formik.errors.confirmPassword
                : " "
            }
            sx={styles.textField}
            fullWidth
            type={showPassword ? "text" : "password"}
            InputProps={{ // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          {errors ? <div style={{ color: 'red', marginBottom: 15 }}><p>Error while changing Password</p></div> : null}
        </Box>

        <Box sx={{
          display: 'flex',
          justifyContent: "center",
        }}>
          <Button type={"submit"} sx={styles.Button}>
            Confirm
          </Button>

        </Box>
      </Container>
      <AlertDialogSlide open={open} setOpen={setOpen} title={""} description={"Password Reset Successfully"} link={"/login"} disbaledEsacpe={true} />
    </form >
  );
};

export default FormComponent;
