export const setToken = (token) => ({
  type: 'SET_TOKEN',
  payload: token,
});

export const setObject = (object) => ({
  type: 'SET_OBJECT',
  payload: object,
});

export const clearToken = (token) => ({
  type: 'CLEAR_TOKEN',
  payload: token,
});

export const clearObject = (object) => ({
  type: 'CLEAR_OBJECT',
  payload: object,
});