import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import styles from './styles'

const ContextSection = () => {
  return (
    <Box sx={styles.container} >
      <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginBottom: 5 }}>
        <Typography sx={styles.mainHeading}>Welcome to Closed Won LLC</Typography>
        <Typography sx={styles.paragraph}>We’re a bootstrapped software company that's all about enabling salespeople to crush their targets, one closed deal at a time. We're based in the heart of Silicon Valley, surrounded by tech giants and trendy coffee shops where aspiring entrepreneurs come to get their caffeine fix and chase exits.

          At Closed Won, we like to do things a little differently. We’re gritty. We believe that every salesperson deserves a shot at the big leagues. Our products are designed to give salespeople the tools they need to compete at the highest level, whether they're working from home or hitting the road. We love sales and salespeople. We believe that a happy team is a productive team, and what could be happier than booking more meetings?

          So, whether you're a seasoned sales pro or a newbie trying to find your footing, Closed Won has got you covered. If you're looking for a partner that's as passionate about sales as you are, look no further. We’re the only AI email generation tool (and yes, there’s a lot) built by salespeople for sales people. We know the grind, we know what it takes, and we’re here to help. Thanks for choosing Closed Won.

        </Typography>
      </Box>

    </Box >
  );
};

export default ContextSection;
