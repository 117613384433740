import { Button } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';


import logo_desk from "../../assets/img/logo_desc.svg";
import logo_mob from "../../assets/img/logo_mob.svg";

import Navbar from "../NavBar";
import styles from "./styles";
import { useSelector } from 'react-redux';

const Header = () => {

  const login = useSelector((state) => state.token)
  const object = useSelector((state) => state.object)

  return (
    <AppBar position='static' sx={styles.appBar}>
      <Container>
        <Toolbar disableGutters>
          <Typography component='a' sx={styles.logoDesk} href='/'>
            <img src={logo_desk} alt='logo' />
          </Typography>
          <Typography component='a' sx={styles.logoMob} href='/'>
            <img src={logo_mob} alt='logo' />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'none', xs: 'none' } }}>
            <Navbar />
          </Box>
          {!login ? <Box sx={styles.buttons}>
            <Button sx={styles.buttonFeilds} href="/login">Login</Button>
            <Button sx={styles.buttonFeilds} href="/signup">SignUp</Button>
          </Box> : <Button sx={styles.dashboardButton} href="/dashboard">{object.emailVerified ? "Go to Dashboard" : "Not Verified"}</Button>}
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default Header
