import { Box, Button, Container, List, ListItem, Stack, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import CONFIG from "../../config";
import LoadingSpinner from "../../Loader/loadingSpinner";
import styles from './styles'
import SubscriptionCancellationAlert from "../../Helper/subscriptionCancelatonAlert";
import SuccessAlert from "../../Helper/SuccessAlert";
import ErrorAlert from "../../Helper/ErrorAlert";
import { useSelector } from "react-redux";


const PricingDetailComponent = () => {
  const token = useSelector((state) => state.token);
  const [showAlert, setShowAlert] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  const [error, setError] = useState(false)
  const [description, setDescription] = useState("")
  const [subscribedPlan, setSubscribedPlan] = useState({
    free: false,
    basic: false,
    pro: false
  })

  useEffect(() => {
    const userPlan = localStorage.getItem("userPlan")

    setSubscribedPlan({
      ...subscribedPlan,
      free: userPlan === 'Free Plan' ? true : false,
      basic: userPlan === 'Basic Plan' ? true : false,
      pro: userPlan === 'Professional Plan' ? true : false,

    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  async function checkoutSession(priceId, customerId) {
    var formdata = new FormData();
    formdata.append("customer_id", customerId);
    formdata.append("price_id", priceId);

    await axios.post(CONFIG.BASE_URL + "api/payments/create-checkout-session/", formdata, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
      .then((response) => {
        window.location.replace(response.data.url)
        return
      }).catch((error) => {
        setError(true)
        console.log("Error", error)
      })
  }

  const basicPayment = async () => {
    if (subscribedPlan.pro) {
      setShowAlert(true)
    } else {
      setIsLoading(true)
      await axios.post(CONFIG.BASE_URL + "api/payments/create-customers-on-strip/", {}, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      })
        .then(async (response) => {
          await checkoutSession(CONFIG.BASIC_SUBSCRIPTION_ID, response.data.data.id)
        }).catch((error) => {
          setError(true)
          setIsLoading(false)
        })
    }
  }

  const proPayment = async () => {
    if (subscribedPlan.basic) {
      setShowAlert(true)
    } else {
      setIsLoading(true)
      await axios.post(CONFIG.BASE_URL + "api/payments/create-customers-on-strip/", {}, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      })
        .then(async (response) => {
          await checkoutSession(CONFIG.PRO_SUBSCRIPTION_ID, response.data.data.id)
        }).catch((error) => {
          setError(true)
          setIsLoading(false)
        })
    }
  }

  const cancelSubscription = async () => {
    setIsLoading(true)
    await axios.post(CONFIG.BASE_URL + "api/payments/cancel-subscription/", {}, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
      .then((response) => {
        setIsLoading(false)
        setSuccessAlert(true)
      }).catch((error) => {
        setError(true)
        setDescription(error.response.data.message)
        setIsLoading(false)

      })
  }

  return (
    isLoading === true ? <Container sx={styles.boxes}><LoadingSpinner /></Container> :
      <Box sx={{ display: "flex", justifyContent: "center", margin: 'auto', padding: 2 }}>
        <Box sx={styles.boxes}>
          <Box sx={subscribedPlan.free === true ? styles.selectedSubBoxes : styles.subBoxes}>
            <List sx={{ gap: 2, display: "flex", justifyContent: "flex-start", flexDirection: "column", }}>
              <ListItem>
                <Box>
                  <Typography sx={subscribedPlan.free === true ? styles.selectedSubHeading : styles.subHeading} >Free</Typography>
                  <Stack sx={{ display: "flex", flexDirection: "row", marginTop: 2 }}>
                    <Typography sx={subscribedPlan.free === true ? styles.selectedPrice : styles.price}>$0.00</Typography>
                    <Typography sx={{ fontSize: "10px !important", color: subscribedPlan.free === true ? "#fff" : "#000" }}>/per month</Typography>
                  </Stack>
                </Box>
              </ListItem>
              <ListItem sx={subscribedPlan.free === true ? styles.selectedParagraph : styles.paragraph}>5 Emails Per Month</ListItem>
              <ListItem sx={subscribedPlan.free === true ? styles.selectedParagraph : styles.paragraph}>AI Email Generation</ListItem>
              <ListItem sx={subscribedPlan.free === true ? styles.selectedNotInOffer : styles.notInOffer}>CSV Support</ListItem>
              <ListItem sx={subscribedPlan.free === true ? styles.selectedNotInOffer : styles.notInOffer}>LinkedIn email generation</ListItem>
            </List>
          </Box>
          <Box sx={subscribedPlan.basic === true ? styles.selectedSubBoxes : styles.subBoxes}>
            <List sx={{ gap: 2, display: "flex", justifyContent: "flex-start", flexDirection: "column", }}>
              <ListItem>
                <Box>
                  <Typography sx={subscribedPlan.basic === true ? styles.selectedSubHeading : styles.subHeading} >Basic</Typography>
                  <Stack sx={{ display: "flex", flexDirection: "row", marginTop: 2 }}>
                    <Typography sx={subscribedPlan.basic === true ? styles.selectedPrice : styles.price}>$9.99</Typography>
                    <Typography sx={{ fontSize: "10px !important", color: subscribedPlan.basic === true ? "#fff" : "#000" }}>/per month</Typography>
                  </Stack>
                </Box>
              </ListItem>
              <ListItem sx={subscribedPlan.basic === true ? styles.selectedParagraph : styles.paragraph}>400 Emails Per Month</ListItem>
              <ListItem sx={subscribedPlan.basic === true ? styles.selectedParagraph : styles.paragraph}>AI Email Generation</ListItem>
              <ListItem sx={subscribedPlan.basic === true ? styles.selectedParagraph : styles.paragraph}>CSV Support</ListItem>
              <ListItem sx={subscribedPlan.basic === true ? styles.selectedNotInOffer : styles.notInOffer}>LinkedIn email generation</ListItem>
            </List>
            {
              subscribedPlan.basic === false ?
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                  <Button sx={styles.buttonFeilds} onClick={basicPayment}>{subscribedPlan.pro ? "Downgrade" : "Upgrade"}</Button>
                </Box> : <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                  <Button sx={styles.buttonFeilds} onClick={cancelSubscription}>Cancel</Button>
                </Box>
            }
          </Box>
          <Box sx={subscribedPlan.pro === true ? styles.selectedSubBoxes : styles.subBoxes}>
            <List sx={{ gap: 2, display: "flex", justifyContent: "flex-start", flexDirection: "column", }}>
              <ListItem>
                <Box>
                  <Typography sx={subscribedPlan.pro === true ? styles.selectedSubHeading : styles.subHeading} >Pro</Typography>
                  <Stack sx={{ display: "flex", flexDirection: "row", marginTop: 2 }}>
                    <Typography sx={subscribedPlan.pro === true ? styles.selectedPrice : styles.price}>$29.99</Typography>
                    <Typography sx={{ fontSize: "10px !important", color: subscribedPlan.pro === true ? "#fff" : "#000" }}>/per month</Typography>
                  </Stack>
                </Box>
              </ListItem>
              <ListItem sx={subscribedPlan.pro === true ? styles.selectedParagraph : styles.paragraph}>2000 Emails Per Month</ListItem>
              <ListItem sx={subscribedPlan.pro === true ? styles.selectedParagraph : styles.paragraph}>AI Email Generation</ListItem>
              <ListItem sx={subscribedPlan.pro === true ? styles.selectedParagraph : styles.paragraph}>CSV Support</ListItem>
              <ListItem sx={subscribedPlan.pro === true ? styles.selectedParagraph : styles.paragraph}>LinkedIn email generation</ListItem>
            </List>
            {
              subscribedPlan.pro === false ?
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                  <Button sx={styles.buttonFeilds} onClick={proPayment}>Upgrade</Button>
                </Box> : <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                  <Button sx={styles.buttonFeilds} onClick={cancelSubscription}>Cancel</Button>
                </Box>
            }
          </Box>
        </Box >
        <SuccessAlert open={successAlert} setOpen={setSuccessAlert} reload={true} description={"You have successfully canceled your subscription."} />
        <SubscriptionCancellationAlert open={showAlert} setOpen={setShowAlert} />
        <ErrorAlert open={error} setOpen={setError} description={description} />
      </Box>
  );
};

export default PricingDetailComponent;
