import { Box, Container } from "@mui/system";
import Footer from "../Footer";
import Header from "../Header";
import FormComponent from "./FormComponent";
import styles from "./styles";

const ResetPassword = () => {
  return (
    <Box >
      <Box sx={styles.container}>
        <Header />
      </Box>

      <Container sx={styles.containerBoxForForm}>
        <FormComponent />
      </Container>
      <Footer />
    </Box >
  )
}

export default ResetPassword
