const styles = {
  container: (theme) => ({
    display: "flex",
    flexDirection: "row",
    marginLeft: 0,
    gap: 2,
    width: "50%",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: 2,
      width: "100%"
    },

  }),
  subContainer: {
    margin: "auto", padding: 5, background: "#fff", display: "flex", justifyContent: "center", flexDirection: "column",
    height: "600px"
  },
  h1: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600 !important",
    fontSize: "24px !important",
    lineHeight: "39px",
    textAlign: "center",
    color: "#C85EF1",
    marginTop: "50px"
  },
  button: {
    background: "linear-gradient(266.85deg, #005CF3 -16.58%, rgba(255, 0, 199, 0.52) 179.36%)", color: "#FFFFFF", fontFamily: "'Inter'",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "21.3333px",
    lineHeight: "29px",
    textTransform: 'none',
    marginBottom: 5
  },
  paragraph: {
    fontFamily: "'Inter'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px !important",
    textAlign: "center",
    color: "#000000",
    marginTop: 2,
    wordWrap: "break-word",
    width: "250px"
  }
}

export default styles
