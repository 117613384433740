import { useState } from "react";
// import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
// import Button from "@mui/material/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import styles from "./styles";
import CONFIG from "../config";
import axios from "axios";
import { Container, Link, Typography } from "@mui/material";
import { LoadingButton } from '@mui/lab';

import AlertDialogSlide from "../Helper/AnimatedButton";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required")
});



const FormComponent = () => {
  // const navigate = useNavigate();
  const [errors, setErrors] = useState(false)
  const [isloading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true)
      var formdata = new FormData();
      formdata.append("email", values.email);

      axios.post(CONFIG.BASE_URL + "api/registration/password-reset/", formdata)
        .then((response) => {
          resetForm()
          setIsLoading(false)
          setOpen(true)
        }).catch((error) => {
          setIsLoading(false)
          setErrors(error.response.data.message)
        })
    },
  });
  const handleOnChange = (event) => {
    setErrors(false)
  };

  return (
    <form onSubmit={formik.handleSubmit} onChange={handleOnChange} >
      <Container sx={styles.formContainer}>

        <Box sx={styles.inputs}>
          <Typography variant="h1" sx={styles.h1}> Enter your Email </Typography>
          <TextField
            placeholder="Email address"
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={
              formik.touched.email && formik.errors.email
                ? formik.touched.email && formik.errors.email
                : " "
            }
            InputProps={{ shrink: "true", }}
            sx={styles.textField}
            fullWidth
          />

          {errors ? <div style={{ color: 'red', marginBottom: 15 }}>{errors}</div> : null}
          <LoadingButton loading={isloading} variant="outlined" type={"submit"} sx={styles.Button}>
            Send
          </LoadingButton>
          <Typography sx={{
            fontFamily: 'Inter',
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "14px",
            color: "#555555",
            marginTop: 2,
          }} variant="subtitle1"> By using Closed Won you agree to the <Link href="#" sx={{
            fontFamily: 'Inter',
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "17px",
            textAlign: "center",
          }}>Terms of Service </Link>
            and <Link href="#" sx={{
              fontFamily: 'Inter',
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "17px",
            }}>Privacy Policy </Link></Typography>
        </Box>
      </Container>
      <AlertDialogSlide open={open} setOpen={setOpen} title={"Password Reset"} description={"  Please check your email, to get the Link to reset your password. Thanks"} />
    </form >
  );
};

export default FormComponent;
