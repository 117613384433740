import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import styles from "./styles";
import CONFIG from "../config";
import axios from "axios";
import { Link, IconButton, InputAdornment, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Container } from "@mui/system";
import { setToken, setObject } from "../../redux/action";
import { useDispatch } from "react-redux";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string()
    .required('Password is required.')
});



const FormComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      var formdata = new FormData();
      formdata.append("email", values.email);
      formdata.append("password", values.password);
      axios.post(CONFIG.BASE_URL + "api/registration/user-login/", formdata)
        .then(async (response) => {
          dispatch(setToken(response.data.tokens.access));
          dispatch(setObject({ emailVerified: response.data.email_is_verified }))
          if (!response.data.email_is_verified) navigate('/verifyOtp')
          else navigate('/dashboard')
        }).catch((error) => {
          setErrors(error.response.data.message)
        })
    },
  });
  const handleOnChange = (event) => {
    setErrors(false)
  };

  return (
    <form onSubmit={formik.handleSubmit} onChange={handleOnChange} >
      <Container sx={styles.formConatiner}>
        <Box sx={styles.inputs}>
          <TextField
            placeholder="Email address"
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={
              formik.touched.email && formik.errors.email
                ? formik.touched.email && formik.errors.email
                : " "
            }
            InputProps={{ shrink: "true", }}
            sx={styles.textField}
            fullWidth
          />
          <TextField
            placeholder="Password"
            id="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={
              formik.touched.password && formik.errors.password
                ? formik.touched.password && formik.errors.password
                : " "
            }
            sx={styles.textField}
            fullWidth
            type={showPassword ? "text" : "password"}
            InputProps={{ // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          {errors ? <div style={{ color: 'red', marginBottom: 15 }}>{errors}</div> : null}
          <Box sx={{ marginBottom: 5 }}>
            <Link href="/forgotPassword">Forget Your Password?</Link>
          </Box>
          <Button type={"submit"} sx={styles.Button}>
            Sign In
          </Button>
          <Typography variant="subtitle1" sx={{ display: "flex", justifyContent: "center", gap: "5px", marginTop: 2 }}> Or <Link href="/signup">Create Account</Link></Typography>
          <Typography sx={{
            fontFamily: 'Inter',
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "14px",
            color: "#555555",
            marginTop: 2,
          }} variant="subtitle1"> By using Closed Won you agree to the <Link href="#" sx={{
            fontFamily: 'Inter',
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "17px",
            textAlign: "center",
          }}>Terms of Service </Link>
            and <Link href="#" sx={{
              fontFamily: 'Inter',
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "17px",
            }}>Privacy Policy </Link></Typography>
        </Box>
      </Container>
    </form >
  );
};

export default FormComponent;
