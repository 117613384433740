const styles = {
  appBar: {
    background: "linear-gradient(85.78deg, #1D09BD -23.77%, #EB01E3 110.4%)",
    color: "#000000"
  },
  container: (theme) => ({
    [theme.breakpoints.up("md")]: {
      margin: "0",
      padding: "0",
    },
  }),
  logoDesk: {
    mr: 2,
    display: { xs: "none", lg: "flex" },
    flexGrow: 1,
    width: "144.77px",
    height: "89.67px"
  },
  logoMob: {
    mr: 2,
    display: { xs: "flex", lg: "none" },
    flexGrow: 1,
    justifyContent: "center"
  },
  title: (theme) => ({
    padding: 1,
    [theme.breakpoints.down("md")]: {
      fontSize: "16px"
    },
  }),
  buttonFeilds: (theme) => ({

    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "20px",
    textAlign: "center",
    background: "linear-gradient(85.78deg, #1D09BD -23.77%, #EB01E3 110.4%)",
    // background: "#FFFFFF",
    borderRadius: "8px",
    border: 1,
    borderColor: "#fff",
    color: "#fff",
    width: "136.5px",
    height: "38.53px",
    textTransform: "none", marginTop: "20px",
    "&:hover": {
      color: "#1D09BD",
      borderColor: "#EB01E3",
      background: "#FFFFFF",
      border: 1
    },
    [theme.breakpoints.down("md")]: {

      width: "136.5px",
      height: "38.53px",
    },
  }),
};

export default styles
