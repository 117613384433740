
import { Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import Footer from "../Footer";
import Header from "../Header";
import FormComponent from "./FormComponent";
import styles from "./styles";

const OTP = () => {

  return (
    <Box >
      <Box sx={styles.container}>
        <Header />
      </Box>
      <Container sx={styles.containers}>
        <Container sx={styles.containerBox}>
          <Container sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            gap: 2
          }}>
            <Typography sx={styles.h1}> Enter your OTP </Typography>
            <Typography sx={styles.paragraph}>Check email for 6 digit code & enter here</Typography>
          </Container>
          <FormComponent />
        </Container>
      </Container>
      <Footer />
    </Box >
  )
}

export default OTP
