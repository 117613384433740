const styles = {
  block: (theme) => ({
    width: "100%",
    height: "100%",
    alignItems: "center",
    background: "radial-gradient(115.05% 275.33% at 3.47% 8.73%, #4D0775 0%, #251337 22.71%, #040013 47.19%, #1A0229 65.21%, #2A033F 82.41%)", /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    [theme.breakpoints.down("md")]: {
      height: "100%"
    }
  }),

}

export default styles
