import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom"





function RequireAuth({ children }) {
  const token = useSelector((state) => state.token);
  const object = useSelector((state) => state.object)
  let authed = token ? true : false
  if (authed) {
    if (object.emailVerified === false) return <Navigate to="/verifyOtp" replace />
    else return children
  } else {
    return <Navigate to="/login" replace />;
  }

}

function WithoutAuth({ children }) {
  const token = useSelector((state) => state.token);
  let authed = token ? true : false
  return authed === false ? children : <Navigate to="/dashboard" replace />;
}

function CustomAuth({ children }) {
  const token = useSelector((state) => state.token);
  const object = useSelector((state) => state.object);
  let authed = token ? true : false
  return authed === true && object.emailVerified === false ? children : <Navigate to="/login" replace />;
}

export { RequireAuth, WithoutAuth, CustomAuth }