import { Container, Typography, Button, Card, CardMedia } from '@mui/material';
import { Box } from '@mui/system';
import Footer from '../Footer';
import Header from '../Header';
import styles from './styles'
import chat_bot from "../../assets/img/chatbot.png";
// import Partners from './components/Partners/Partners';
import ContextSection from './components/ContextSection/ContextSection';
import HowItsWork from './components/HowItsWork/HowItsWork';
// import BookMeeting from './components/BookMeeting/BookMeeting';
// import Testimonials from './components/Testimonials/Testimonials';

const HomePage = () => {
  return (
    <Box sx={{ width: '100%', margin: 0 }}>
      <Box sx={styles.block}>
        <Header />

        <Container sx={styles.mainBanner} >
          <Box sx={styles.mainBannerItem}>
            <Typography sx={styles.mainBannerHeading}>Transform your sales prospecting game with the power of AI.</Typography>
            <Typography variant='subtitle2' sx={{
              fontFamily: "'Roboto'",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "20px",
              lineHeight: "27px",
              color: "#FFFFFF",
              marginTop: "20px"
            }}>Closed Won enables you to effortlessly craft personalized cold emails, without wasting hours trying to perfect them. </Typography>
            <Box sx={styles.buttons}>
              <Button sx={styles.buttonFeilds} href="/signup">Try for free</Button>
              <Button sx={styles.buttonFeilds} href="/pricing">Go Premium</Button>
            </Box>
          </Box>
          <Box>
            <Card sx={styles.mainBannerImage}>
              <CardMedia
                component="img"
                image={chat_bot}
                alt="Main Banner Image"
              />
            </Card>
          </Box>
        </Container>
        {/* <Container sx={{ margin: 'auto', padding: 5 }}>
          <Partners />
        </Container> */}
      </Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
      }}>
        <ContextSection />
      </Box>
      <Box sx={{ margin: 'auto', padding: 2 }}>
        <HowItsWork />
      </Box>
      {/* <Box sx={styles.onlyForDesktopView}>
        <HowItsWork />
      </Box> */}
      {/* <Box sx={styles.onlyForDesktopView}>
        <Testimonials />
      </Box> */}
      {/* <Box sx={styles.onlyForDesktopView}>
        <BookMeeting />
      </Box> */}
      <Footer />
    </Box >
  );
};

export default HomePage;
