import { CardMedia, Typography, Button } from "@mui/material";
import Email from "../../../../assets/dashboard/emails.png"
import ColdEmail from "../../../../assets/dashboard/coldeEmailGeneration.png"
import { Box, Container } from "@mui/system";
import styles from './styles'
const CardComponent = () => {
  return (

    <Container sx={styles.container}>
      <Box sx={styles.subContainer}>
        <CardMedia
          component="img"
          alt="Cold email"
          image={Email}
          sx={{
          }}
        />
        <Box>
          <Typography sx={styles.h1}>
            Cold Email Generation
          </Typography>
          <Typography sx={styles.paragraph}>
            Use AI to generate emails
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 5 }}>
          <Button variant="outlined" sx={styles.button} href="/coldEmail">
            Write Now
          </Button>
        </Box>
      </Box>
      <Box sx={styles.subContainer}>
        <CardMedia
          component="img"
          alt="Cold email"
          image={ColdEmail}
        />
        <Box>
          <Typography
            sx={styles.h1}
          >
            LinkedIn Prospecting Assistant
          </Typography>
          <Typography sx={styles.paragraph}>
            Use AI to generate email based on Linkedin profile.
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 5 }}>
          <Button variant="outlined" sx={styles.button} href="/linkedinEmail">
            Write Now
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default CardComponent;

