import { CardMedia, Typography } from '@mui/material';
import { Box, Container, Stack } from '@mui/system';
import styles from './styles'
import Image from '../../../../assets/partners/context.png'

const ContextSection = () => {
  return (
    <Box sx={styles.container} >
      <Stack sx={styles.subContainerHeading} >
        <Typography sx={styles.mainHeading}>Send personalized emails at scale</Typography>
        <Typography sx={styles.paragraph}>
          Say goodbye to the tedious task of crafting the perfect cold email and focus on what really matters: running demos, nurturing relationships, closing deals, and hitting quotas.
          Upgrade your sales game with Closed Won today.
        </Typography>
      </Stack>
      <Container sx={styles.subContainer}>
        <Box>
          <CardMedia
            component="img"
            image={Image}
            alt="Context Image 1"
            sx={styles.mainBannerImage}
          />
        </Box>
        <Box sx={styles.subContainerParagraph}>
          <Typography sx={styles.mainHeading}>Cold email generation</Typography>
          <Typography sx={styles.paragraph}>
            Our powerful AI technology makes it easy to create personalized emails in just minutes. Simply input your notes and our tool will generate a custom email. You'll be able to create engaging and effective emails with ease, freeing up more time to focus on what really matters - building relationships and closing deals. Whether you're a seasoned sales professional or just starting out, our email generation tool is the perfect solution to take your sales outreach to the next level.
          </Typography>
        </Box>
      </Container>
      <Container sx={styles.subContainer}>
        <Box sx={styles.subContainerParagraph}>
          <Typography sx={styles.mainHeading}>LinkedIn Prospecting assistant</Typography>
          <Typography sx={styles.paragraph}>Automatically gather key information from a prospect's LinkedIn page and generate a personalized email. The LinkedIn Prospecting Assistant makes it possible for sales professionals to reach out to more prospects in less time, while still maintaining a personal touch.
          </Typography>
        </Box>

        <Box>
          <CardMedia
            component="img"
            image={Image}
            alt="Context Image 1"
            sx={styles.mainBannerImage}
          />
        </Box>

      </Container>
    </Box >
  );
};

export default ContextSection;
