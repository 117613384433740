import ForgotPasswordComponent from '../FrogotPassword'

const ForgotPassword = () => {
  return (
    <div>
      <ForgotPasswordComponent />
    </div>
  )
}

export default ForgotPassword
