import HomePage from "../HomePage"

const Home = () => {
  return (

    <HomePage />
  )
}

export default Home
