import { Link } from "@mui/material/";
import DropDown from "./DropDown";

import styles from "./styles";

const BtnNav = ({ page }) => {
  return (
    <>
      {page.arrow ? (
        <DropDown page={page} />
      ) : (
        <Link
          href={page.link}
          sx={styles.btnLink}
        >
          {page.title}
        </Link>
      )}
    </>
  );
};

export default BtnNav;

