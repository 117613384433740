
import { Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import Footer from "../Footer";
import Header from "../Header";
import styles from "./styles";

const NotFoundComponent = () => {
  return (
    <Box>
      < Box sx={styles.container} >
        <Header />
      </Box >
      <Container>
        <Box sx={styles.containers}>
          <Typography variant='h1' sx={styles.h1}> 404 Error </Typography>
          <Typography variant='subtitle1' sx={styles.paragraph}>Page Not Found</Typography>
        </Box>
      </Container>
      <Footer />
    </Box >
  )
}

export default NotFoundComponent
