const styles = {
  container: (theme) => ({
    background: "radial-gradient(115.05% 275.33% at 3.47% 8.73%, #4D0775 0%, #251337 22.71%, #040013 47.19%, #1A0229 65.21%, #2A033F 82.41%)"/* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    [theme.breakpoints.down("md")]: {
    },
  }),
  inputs: {
    display: 'flex',
    width: "400px",
    flexDirection: "column"
  },
  textField: (theme) => ({
    width: '100%',
    '& input::placeholder': {
      fontSize: '20px',
      lineHeight: '20px',
    },
    [theme.breakpoints.up("xs")]: { width: "100%" },
  }),
  block: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  text: {
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "22px",
    /* identical to box height, or 180% */
    letterSpacing: "2.5px",
    textTransform: "capitalize",
    color: "#696969"
  },
  h1: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "40px",
    lineHeight: "48px",
    color: "#C85EF1",
    textAlign: "center"
  },
  paragraph: {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px !important",
    lineHeight: "32px",
    color: "#696969",
    height: "65px",
    textAlign: "center"

  },
  containers: (theme) => ({
    display: "flex", flexDirection: "row", height: "700px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column"
    },
  }),
  containerBox: (theme) => ({
    margin: "auto",
    padding: "50px",
    width: "50%",
    [theme.breakpoints.down("md")]: {
      padding: "10px",
      width: "100%",
    },
  }
  ),
  containerBoxForForm: (theme) => ({
    width: "50%",
    paddingTop: "50px",
    marginLeft: "20px",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      padding: "10px",
      width: "100%",
    },
  }
  ),
  divider: (theme) => ({
    display: 'none',
    [theme.breakpoints.up("md")]: {
      display: "block",
      height: "80%",
      marginTop: "4%"
    },
  }),
  verifyButton: (theme) => ({
    width: "314px",
    height: "53px",
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "19px",
    marginBottom: "10px",
    color: "#fff",
    background: "linear-gradient(266.85deg, #005CF3 -16.58%, rgba(255, 0, 199, 0.52) 179.36%)",
    borderRadius: "26.5px",
    [theme.breakpoints.down("md")]: {
      width: "150px",
      height: "30px",
      fontFamily: 'Inter',
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "10px",
      lineHeight: "19px",
      marginBottom: "10px",
      color: "#fff",
      background: "linear-gradient(266.85deg, #005CF3 -16.58%, rgba(255, 0, 199, 0.52) 179.36%)",
      borderRadius: "26.5px"
    },
  })
};

export default styles
