const initialState = {};

const objectReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_OBJECT':
      return action.payload;
    case 'CLEAR_OBJECT':
      return {};
    default:
      return state;
  }
};

export default objectReducer;