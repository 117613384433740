import BlogComponent from "../Blog"

const Blog = () => {
  return (

    <BlogComponent />
  )
}

export default Blog
