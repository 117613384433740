
import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Alert, Box, Container, Typography } from "@mui/material";

import axios from "axios";
import CONFIG from "../../config";
import styles from './styles';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/system';
import LoadingSpinner from '../../Loader/loadingSpinner';
import { useSelector } from 'react-redux';


export default function Upload() {

  const token = useSelector((state) => state.token);
  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const onDrop = useCallback((acceptedFiles) => {

    acceptedFiles.forEach((file) => {
      setSuccess(null)
      setError(null)
      setIsLoading(true)
      var formdata = new FormData();

      formdata.append('csv_file', file);

      var config = {
        method: 'post',
        url: CONFIG.BASE_URL + 'api/email/bulk-email-generation/',
        headers: {
          "Authorization": `Bearer ${token}`
        },
        data: formdata
      };

      axios(config)
        .then(function (response) {
          setSuccess(response.data.message)
          setIsLoading(false)
        })
        .catch(function (error) {
          setIsLoading(false)
          console.log(error)
          if ('csv_file' in error.response.data) {
            setError(error.response.data.csv_file)
          }
          if ('message' in error.response.data) {
            setError(error.response.data.message)
          }
          if ('detail' in error.response.data) {
            setError(error.response.data.detail)
          }
        });
    })
    // eslint-disable-next-line
  }, [])
  const { getRootProps, getInputProps, open } = useDropzone({ onDrop, noClick: true })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Container sx={{ display: "flex", marginTop: 5, flexDirection: "column", gap: 5, justifyContent: "center" }}>
        {isLoading ? <Stack sx={styles.container}><LoadingSpinner /></Stack> :
          < Stack sx={styles.container}>
            <Typography sx={styles.text}> Import the Bulk File </Typography>
            <Typography sx={styles.h1}> Drag or Drop File Here </Typography>
            <Box display={"flex"} justifyContent={"center"} pt={2}>
              <LoadingButton type="button" onClick={open} sx={styles.Button}>
                Select File
              </LoadingButton>
            </Box>
            <Box display={"flex"} justifyContent={"center"} pt={5}>
              {error && <Alert severity="error">{error}</Alert>}
              {success && <Alert severity="success">{success}</Alert>}
            </Box>
          </Stack>
        }

      </Container>

    </div >
  )
}
