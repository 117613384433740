const styles = {
  container: (theme) => ({
    // background: "linear-gradient(85.78deg, #1D09BD -23.77%, #EB01E3 110.4%)",
    paddingTop: 10,
    [theme.breakpoints.down("md")]: {
      margin: "0",
      padding: "0",
    },
  }),
  mainFooter: (theme) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: 35,
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      display: "grid",
      gap: 2,
      justifyContent: "flex-start"
    },
  }),

  footerLogo: (theme) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: "center",
  }),
  footerColumn: (theme) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column',
    },
  }),
  logoDesk: {
    mr: 2,
    flexGrow: 1
  },

  listing: {
    color: "#fff",
    padding: "20px",
    alignItems: "start"

  },
  listingText: {
    fontFamily: 'Plus Jakarta Sans',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "23px"
  },
  listingTextChild: {
    fontFamily: 'Plus Jakarta Sans',
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "150%"
  },
  copyright: (theme) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "150%",
    color: "#000000",
    display: "flex",
    justifyContent: "center",
    padding: 2,
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
  })
};

export default styles
