// import { Box, List, ListItem } from '@mui/material';

import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
// import logo_desk from "../../assets/img/logo_desc.svg";
// import logo_mob from "../../assets/img/logo_mob.svg";

import styles from "./styles";

const Footer = () => {
  return (
    <Box sx={styles.container}>
      {/* <Box sx={styles.footerLogo}>
        <Typography component='a' href='/'>
          <img src={logo_desk} alt='logo' style={{ width: "150px", padding: 10 }} />
        </Typography>
      </Box> */}
      {/* <Box sx={styles.mainFooter}>

        <Box sx={styles.footerColumn}>
          <List sx={styles.listing}>
            <ListItem sx={styles.listingText}>Contact Us</ListItem>
            <ListItem sx={styles.listingTextChild}>Our Team</ListItem>
            <ListItem sx={styles.listingTextChild}>About Us</ListItem>
            <ListItem sx={styles.listingTextChild}>Our History</ListItem>
            <ListItem sx={styles.listingTextChild}>Technologies</ListItem>
          </List>
          <List sx={styles.listing}>
            <ListItem sx={styles.listingText}>Our Resources</ListItem>
            <ListItem sx={styles.listingTextChild}>The Blog</ListItem>
            <ListItem sx={styles.listingTextChild}>Downloads</ListItem>
            <ListItem sx={styles.listingTextChild}>Change Log</ListItem>
            <ListItem sx={styles.listingTextChild}>Slack Channel</ListItem>
          </List>
          <List sx={styles.listing}>
            <ListItem sx={styles.listingText}>Our Product</ListItem>
            <ListItem sx={styles.listingTextChild}>New Features</ListItem>
            <ListItem sx={styles.listingTextChild}>Roadmap</ListItem>
            <ListItem sx={styles.listingTextChild}>Product Demo</ListItem>
            <ListItem sx={styles.listingTextChild}>Pricing</ListItem>
          </List>
        </Box>
      </Box> */}
      <Typography variant="h5" sx={styles.copyright}>{new Date().getFullYear()} © Closed Won. All Rights Reserved</Typography>
    </Box >
  )
}

export default Footer
