
import { Box, Typography } from "@mui/material";
import PricingDetailComponent from "./detailComponent";
import { useEffect, useState } from "react";
import axios from "axios";
import CONFIG from "../../config";
import LoadingSpinner from "../../Loader/loadingSpinner";
import { useSelector } from "react-redux";

export default function MainComponent() {
  const token = useSelector((state) => state.token);
  const [isLoading, setIsLoading] = useState(false)
  const [userPlan, setUserPlan] = useState("")

  useEffect(() => {
    getUserPlan()
    // eslint-disable-next-line
  }, [])

  const getUserPlan = async () => {
    setIsLoading(true)
    await axios.get(CONFIG.BASE_URL + "api/payments/user-current-plan", {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
      .then((response) => {
        setIsLoading(false)
        if (response.data.status_code === 200) {
          localStorage.setItem("userPlan", response.data.data.current_plan)
          setUserPlan(response.data.data)
        }
      }).catch((error) => {
        setIsLoading(false)
      })

  }

  return (
    isLoading ? <Box sx={{ display: "flex", justifyContent: "flex-start", flexDirection: "column", padding: 2 }}><LoadingSpinner /> </Box> :
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", justifyContent: "flex-start", flexDirection: "column", padding: 2 }}>
          <Typography sx={{ marginBottom: "10px" }}> Current Activated Plan: {!userPlan.have_a_subscription ? "Free Trial" : userPlan.current_plan}  </Typography>
          <Typography sx={{ fontSize: "16px !important", color: "#000" }}> Valid till: {userPlan.ends_at}</Typography>
        </Box>
        <PricingDetailComponent userPlan={userPlan} />
      </Box>
  )
}
