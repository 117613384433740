import { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import styles from "./styles";
import CONFIG from "../../../config";
import axios from "axios";
import contents from "../contents";
import { Autocomplete, InputLabel, MenuItem, Stack, Typography } from "@mui/material";
import UpgradeAlert from "../../../Helper/upgradeAlert";
import { useSelector } from "react-redux";


const validationSchema = yup.object({
  linkedinUrl: yup
    .string()
    .url()
    .required("URL is required"),
  intent: yup
    .string()
    .required("Intent is required"),
  tone: yup.string()
    .required('Tone is required '),
  description:
    yup.string()
      .required('Description is required')
});

const Tone = ["Formal", "Friendly", "Witty", "Persuasive"]

const FormComponent = ({ setData, data, setIsLoading, isLoading }) => {
  const token = useSelector((state) => state.token);

  const [open, setOpen] = useState(false)
  const [errors, setErrors] = useState(false)

  const formik = useFormik({
    initialValues: {
      intent: "",
      tone: "Formal",
      description: "",
      linkedinUrl: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true)
      var formdata = new FormData();
      formdata.append("intent", values.intent);
      formdata.append("tone", values.tone);
      formdata.append("description", values.description);
      formdata.append("linkedin_url", values.linkedinUrl)
      axios.post(CONFIG.BASE_URL + "api/email/linkedin-email-generation/", formdata, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      })
        .then((response) => {
          setData([...data, response.data.data])
          setIsLoading(false)
        }).catch((error) => {
          setIsLoading(false)
          if ('detail' in error.response.data) {
            setOpen(true)
            setErrors(error.response.data.detail)
          } else setErrors(true)

        })
    },
  });
  const handleOnChange = (event) => {
    console.log(errors)
    setErrors(false)
  };



  return (
    <form id="linkedinEmail" onSubmit={formik.handleSubmit} onChange={handleOnChange}  >
      <Stack sx={{
        margin: "auto",
        padding: 5
      }}>
        <Box>
          <InputLabel sx={{
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "21.3333px !important",
            lineHeight: "29px",
            color: "#000000"
          }}>Paste URL of Linkedin</InputLabel>
          <TextField
            placeholder="URL"
            id="linkedinUrl"
            name="linkedinUrl"
            value={formik.values.linkedinUrl}
            onChange={formik.handleChange}
            error={formik.touched.linkedinUrl && Boolean(formik.errors.linkedinUrl)}
            helperText={
              formik.touched.linkedinUrl && formik.errors.linkedinUrl
                ? formik.touched.linkedinUrl && formik.errors.linkedinUrl
                : " "
            }
            InputProps={{ style: { fontSize: "20px" }, shrink: "true", }}
            sx={styles.textField}
            fullWidth
            variant="filled"
          />
        </Box>
        <Box>
          <InputLabel sx={{
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "21.3333px !important",
            lineHeight: "29px",
            color: "#000000"
          }}>Intent</InputLabel>
          <Autocomplete
            id="intent"
            size={"medium"}
            options={contents}
            getOptionLabel={(option) => {
              // e.g value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.item;
            }}
            selectOnFocus
            handleHomeEndKeys
            renderOption={(props, option) => <li style={{ fontSize: "16px" }} {...props} key={option.id}>{option.item}</li>}
            freeSolo
            renderInput={(params) =>
              <TextField
                {...params}
                value={formik.values.intent}
                onChange={(e) => {
                  formik.setFieldValue("intent", e.target.value)
                }}
                onSelect={(e) => {
                  formik.setFieldValue("intent", e.target.value)
                }}
                placeholder="Select or Add your intent here !"
                error={formik.touched.intent && Boolean(formik.errors.intent)}
                helperText={
                  formik.touched.intent && formik.errors.intent
                    ? formik.touched.intent && formik.errors.intent
                    : " "
                }
                variant="filled"
              />
            }
            sx={styles.textField}
          />
        </Box>
        <Box>
          <InputLabel sx={{
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "21.3333px !important",
            lineHeight: "29px",
            color: "#000000"
          }}>Tone</InputLabel>
          <TextField
            select
            id="tone"
            name="tone"
            value={formik.values.tone}
            onChange={formik.handleChange}
            error={formik.touched.tone && Boolean(formik.errors.tone)}
            helperText={
              formik.touched.tone && formik.errors.tone
                ? formik.touched.tone && formik.errors.tone
                : " "
            }
            InputProps={{ style: { fontSize: "16px", color: "#000000" }, shrink: "true", }}
            sx={styles.textField}
            fullWidth
            variant="filled"
          >
            <MenuItem key="" value="" sx={{ fontSize: "16px !important" }} disabled> Please Select</MenuItem>
            {
              Tone.map((tone, index) =>
                <MenuItem key={index} value={tone} sx={{ fontSize: "16px !important" }}>
                  {tone}
                </MenuItem>
              )
            }
          </TextField>

        </Box>
        <Box>
          <Typography sx={{
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "21.3333px !important",
            lineHeight: "29px",
            color: "#000000"
          }}> Write a Short Description about Your Email </Typography>
          <TextField
            placeholder="Write any details about the email"
            id="description"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={
              formik.touched.description && formik.errors.description
                ? formik.touched.description && formik.errors.description
                : " "
            }
            InputProps={{ style: { fontSize: "16px", color: "#000000" } }}
            sx={styles.description}
            multiline
            maxRows={5}
            rows={5}
            variant="filled"
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button type={"submit"} sx={styles.button}>
            Generate Text
          </Button>
        </Box>
      </Stack>
      <UpgradeAlert setOpen={setOpen} open={open} />
    </form >
  );
};

export default FormComponent;
