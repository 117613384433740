
import { Chip, Divider, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import Footer from "../Footer";
import Header from "../Header";
import FormComponent from "./FormComponent";
import styles from "./styles";
import Googlelogin from "../Helper/GoogleLogin";

const Login = () => {
  return (
    <Box>
      < Box sx={styles.container} >
        <Header />
      </Box >
      <Container sx={{ marginBottom: 10 }}>
        <Box sx={styles.containers}>
          <Container sx={styles.containerBox}>
            <Typography variant='h5' sx={styles.text}> Closed Won </Typography>
            <Typography variant="h1" sx={{
              fontFamily: "Rubik",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "40px",
              lineHeight: "48px",
              color: "#C85EF1",
            }}> Welcome </Typography>
            <Typography variant='subtitle1' sx={styles.paragraph}>Thank you for trusting us with your outbound sales. Let’s book some meetings.</Typography>
          </Container>
          <Divider />
          <Divider orientation="vertical" variant="middle" sx={styles.divider} />
          <Container sx={styles.containerBoxForForm}>
            <Typography variant="h1" sx={styles.h1}> Login to Your Account </Typography>
            <Container sx={styles.googleButtonStyling}>
              <Googlelogin text="Continue with Google" />
            </Container>
            <Divider sx={{ margin: "auto", padding: 2, width: "50%" }}>
              <Chip label="Or" />
            </Divider>
            <FormComponent />
          </Container>
        </Box>
      </Container>
      <Footer />
    </Box >
  )
}

export default Login
