import LinkedinEmailComponent from "../LinkedinEmail"


const LinkedinEmail = () => {
  return (
    <LinkedinEmailComponent />
  )
}

export default LinkedinEmail
