import { Typography, Divider, Button, TextField, List } from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import FormComponent from "../Form/MainComponent";
import { useState } from "react";
import LoadingSpinner from "../../../Loader/loadingSpinner";
import styles from "./styles";

const CardComponent = () => {
  const [data, setData] = useState([])
  const [isloading, setIsLoading] = useState(false)

  return (
    <Container sx={styles.container}>
      <Box border={"1.33333px solid rgba(0, 0, 0, 0.25)"} sx={styles.subContainer}>
        <Container>
          <Typography sx={styles.h1}> Cold Email Generation</Typography>
          <Typography sx={styles.paragraph}>Use AI generated emails for your work</Typography>
        </Container>
        <Divider />
        <FormComponent setData={setData} data={data} setIsLoading={setIsLoading} isLoading={isloading} />
      </Box>
      <Box border={"1.33333px solid rgba(0, 0, 0, 0.25)"} sx={styles.subContainer}>
        <Stack padding={3} >
          <List style={{ maxHeight: '600px', overflow: 'auto' }} >
            {data && data.map((item) => <Box bgcolor={"#F2F2F2"} marginTop={4}>
              <TextField value={item.intent} fullWidth variant="standard" InputProps={{ style: { fontSize: "21px", padding: 2 } }} ></TextField>
              <Divider />
              <TextField InputProps={{ style: { fontSize: "18px", padding: 5 } }} value={item.cold_email} maxRows={10} rows={10} multiline fullWidth variant="standard" />
            </Box>)}
          </List>
          {isloading && <Box sx={{ display: 'flex', justifyContent: "center", marginTop: 2 }}><LoadingSpinner /> </Box>}
          <Box sx={{ padding: "20px", justifyContent: "center" }}>
            <Container sx={{
              display: "flex",
              justifyContent: "center"
            }}>
              <Button type={"submit"} sx={{
                fontFamily: 'Inter',
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "16px !important",
                lineHeight: "19px",
                color: "#555555",
                border: "1.33333px solid rgba(0, 0, 0, 0.25)",
                padding: 2,
              }}
                form="coldEmail"
              >
                More Like This
              </Button>
            </Container>
          </Box>
        </Stack>
      </Box>
    </Container >
  );
};

export default CardComponent;

