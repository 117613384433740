const styles = {
  container: (theme) => ({
    background: '#FFFFFF',
    margin: "auto",
    padding: 5,
    width: "max-content",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  }),
  mainHeading: (theme) => ({
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: '400 !important',
    fontSize: "30px !important",
    lineHeight: "48px !important",
    color: "#C85EF1",
    textAlign: 'center',
    marginBottom: "1%",
    [theme.breakpoints.down("lg")]: {
      lineHeight: "40px !important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "18px !important",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "8px !important",
    },
  }),
  paragraph: (theme) => ({
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: '400 !important',
    fontSize: "16px !important",
    lineHeight: "32px !important",
    textAlign: "center",
    color: "#696969",
    [theme.breakpoints.down("lg")]: {
      lineHeight: "20px !important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "12px !important",
    },
  }),
  subContainer: (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    margin: 'auto',
    padding: 10,
    gap: 5,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      flexDirection: 'column',
      padding: 1,
    },
  }),
  mainBannerImage: (theme) => ({
    width: "100%",
    margin: 'auto',
    [theme.breakpoints.down("md")]: {
      padding: 0,
      width: "70%"
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      width: "100%"
    },
  }),
  subContainerParagraph: (theme) => ({
    margin: 'auto', padding: 4, width: "50%",
    [theme.breakpoints.down("md")]: {
      margin: 'auto', padding: 1,
      width: "100%",
    },
  }),
  subContainerHeading: (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 5,
    margin: 'auto',
    width: "50%",
    [theme.breakpoints.down("md")]: {
      margin: 'auto',
      padding: 1,
      width: "100%",
    },
  }),


}

export default styles
