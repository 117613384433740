const styles = {
  container: (theme) => ({
    background: "radial-gradient(115.05% 275.33% at 3.47% 8.73%, #4D0775 0%, #251337 22.71%, #040013 47.19%, #1A0229 65.21%, #2A033F 82.41%)"/* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    [theme.breakpoints.down("md")]: {
    },
  }),
  inputs: (theme) => ({
    display: 'flex',
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 5,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      '& input::placeholder': {
        fontSize: '12px',
        lineHeight: '12px',
      },
    },
  }),
  formContainer: (theme) => ({
    margin: "auto",
    paddingTop: "10px",
    [theme.breakpoints.up("md")]: {
    },
  }),
  textField: (theme) => ({
    width: '100%',
    '& input::placeholder': {
      fontSize: '20px',
      lineHeight: '20px',
    },
    [theme.breakpoints.down("md")]: {
      '& input::placeholder': {
        fontSize: '12px',
        lineHeight: '12px',
      },
    },
  }),
  block: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  text: {
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "22px",
    /* identical to box height, or 180% */
    letterSpacing: "2.5px",
    textTransform: "capitalize",
    color: "#696969"
  },
  h1: (theme) => ({
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "40px",
    lineHeight: "48px",
    color: "#C85EF1",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      fontSize: '25px',
      fontWeight: "400",
      lineHeight: '20px !important',
      marginBottom: 2
    },
  }),
  paragraph: {
    "fontFamily": "'Roboto'",
    "fontStyle": "normal",
    "fontWeight": "400",
    "fontSize": "18px",
    "lineHeight": "32px",
    "color": "#696969",
    "height": "65px"
  },
  containers: (theme) => ({
    display: "flex", flexDirection: "row",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column"
    },
  }),
  containerBox: (theme) => ({
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      height: "300px"
    },
  }
  ),
  containerBoxForForm: (theme) => ({
    padding: 5,
    margin: "auto",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      margin: 0,
      padding: 0,
      marginTop: 5
    },
  }
  ),
  googleButtonStyling: (theme) => ({
    paddingTop: 1,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      margin: 0,
      padding: 0,
      marginTop: 5
    },
  }),
  divider: (theme) => ({
    display: 'none',
    [theme.breakpoints.up("md")]: {
      display: "block",
      height: "400px",
      padding: 4,
      margin: "auto"
    },
  }),
  Button: (theme) => ({
    height: "53px",
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "19px",
    width: "250px",
    color: "#fff",
    background: "linear-gradient(266.85deg, #005CF3 -16.58%, rgba(255, 0, 199, 0.52) 179.36%)",
    borderRadius: "26.5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "100px",
      height: "40px",
      fontSize: "12px",
    },
  })
};

export default styles
