// material-ui
import { styled } from '@mui/material/styles';
// import { Button, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { Card, CardContent, Grid, Typography } from '@mui/material';

// project imports
// import AnimateButton from './AnimatedButton';

// styles
const CardStyle = styled(Card)(({ theme }) => ({
  background: "#9804D3",
  marginTop: '16px',
  marginBottom: '16px',
  overflow: 'hidden',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    width: '200px',
    height: '200px',
    border: '19px solid ',
    borderColor: "#FFF",
    borderRadius: '50%',
    top: '65px',
    right: '-150px'
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    width: '200px',
    height: '200px',
    border: '3px solid ',
    borderColor: "#FFF",
    borderRadius: '50%',
    top: '145px',
    right: '-70px'
  }
}));

// ==============================|| PROFILE MENU - UPGRADE PLAN CARD ||============================== //

export default function UpgradePlanCard({ plan }) {
  return < CardStyle >
    <CardContent>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography sx={{ fontSize: '1rem', fontWeight: 600, color: "#000000" }}>Upgrade your plan</Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" color="black" sx={{ opacity: 0.6 }}>
            Your current plan is: {plan}
          </Typography>
        </Grid>
        {/* <Grid item>
          <Stack direction="row">
            <AnimateButton>
              <Button variant="contained" color="warning" sx={{ boxShadow: 'none' }} href="/subscription">
                Go Premium
              </Button>
            </AnimateButton>
          </Stack>
        </Grid> */}
      </Grid>
    </CardContent>
  </CardStyle >
}
