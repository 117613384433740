const styles = {
  googleButton: {
    height: "53px",
    color: "#000000",
    textTransform: 'none',
    borderColor: "#000000",
    padding: 3.5,

  },
  text: (theme) => ({
    padding: 1,
    fontSize: "16px !important",
    fontWeight: 400,
    lineHeight: "16px",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px !important",
    },
  })
};

export default styles
