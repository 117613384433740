import { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import styles from "./styles";
import CONFIG from "../config";
import axios from "axios";
import { Link, IconButton, InputAdornment, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Container } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setObject, setToken } from "../../redux/action";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string()
    .required('Password is required.')
    .min(8, 'Password must be 8 characters long')
    .matches(/[0-9]/, 'Password requires a number')
    .matches(/[a-z]/, 'Password requires a lowercase letter')
    .matches(/[A-Z]/, 'Password requires an uppercase letter')
    .matches(/[^\w]/, 'Password requires a symbol'),
  confirmPassword: yup.string()
    .required('Confirm Password is required.')
    .oneOf([yup.ref('password'), null], 'Must match "password" field value'),

  firstName: yup.string()
    .required("First Name is required"),
  lastName: yup.string()
    .required("Last Name is required"),
});



const FormComponent = () => {

  const dispatch = useDispatch();
  const [errors, setErrors] = useState(null)
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false
  });
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      var formdata = new FormData();
      formdata.append("email", values.email);
      formdata.append("password", values.password);
      formdata.append("confirm_password", values.confirmPassword);
      formdata.append("first_name", values.firstName);
      formdata.append("last_name", values.lastName);

      axios.post(CONFIG.BASE_URL + "api/registration/user-signup/", formdata)
        .then((response) => {
          dispatch(setObject({ emailVerified: false }))
          dispatch(setToken(response.data.tokens.access));
          navigate('/verifyOtp')
        }).catch((error) => {
          if ('email' in error.response.data) {
            setErrors(error.response.data.email)
          } else setErrors(error.response.data.message)
        })
    },
  });
  const handleOnChange = (event) => {
    setErrors(null)
  };

  return (
    <form onSubmit={formik.handleSubmit} onChange={handleOnChange} >
      <Container sx={styles.formConatiner}>
        <Box sx={styles.inputs}>
          <TextField
            placeholder="First Name"
            id="firstName"
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={
              formik.touched.firstName && formik.errors.firstName
                ? formik.touched.firstName && formik.errors.firstName
                : " "
            }
            InputProps={{ shrink: "true", }}
            sx={styles.textField}
            fullWidth
          />
          <TextField
            placeholder="Last Name"
            id="lastName"
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={
              formik.touched.lastName && formik.errors.lastName
                ? formik.touched.lastName && formik.errors.lastName
                : " "
            }
            InputProps={{ shrink: "true", }}
            sx={styles.textField}
            fullWidth
          />
          <TextField
            placeholder="Email address"
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={
              formik.touched.email && formik.errors.email
                ? formik.touched.email && formik.errors.email
                : " "
            }
            InputProps={{ shrink: "true", }}
            sx={styles.textField}
            fullWidth
          />
          <TextField
            placeholder="Password"
            id="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={
              formik.touched.password && formik.errors.password
                ? formik.touched.password && formik.errors.password
                : " "
            }
            sx={styles.textField}
            fullWidth
            type={showPassword.password ? "text" : "password"}
            InputProps={{ // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setShowPassword({
                        ...showPassword,
                        password: !showPassword.password
                      })
                    }}
                    onMouseDown={() => {
                      setShowPassword({
                        ...showPassword,
                        password: !showPassword.password
                      })
                    }}
                  >
                    {showPassword.password ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <TextField
            placeholder="Confirm Password"
            id="confirmPassword"
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
                ? formik.touched.confirmPassword && formik.errors.confirmPassword
                : " "
            }
            sx={styles.textField}
            fullWidth
            type={showPassword.confirmPassword ? "text" : "password"}
            InputProps={{ // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setShowPassword({
                        ...showPassword,
                        confirmPassword: !showPassword.confirmPassword
                      })
                    }}
                    onMouseDown={() => {
                      setShowPassword({
                        ...showPassword,
                        confirmPassword: !showPassword.confirmPassword
                      })
                    }}
                  >
                    {showPassword.confirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          {errors ? <div style={{ color: 'red', marginBottom: 15 }}>{errors}</div> : null}
          <Button type={"submit"} sx={styles.Button}>
            Create Account
          </Button>
          <Typography variant="subtitle1" sx={{ display: "flex", justifyContent: "center", gap: "5px", marginTop: 2 }}> Already have an Account? <Link href="/login">Sign In</Link></Typography>
          <Typography sx={{
            fontFamily: 'Inter',
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "14px",
            color: "#555555",
            marginTop: 2,
          }} variant="subtitle1"> By using Closed Won you agree to the <Link href="#" sx={{
            fontFamily: 'Inter',
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "17px",
            textAlign: "center",
          }}>Terms of Service </Link>
            and <Link href="#" sx={{
              fontFamily: 'Inter',
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "17px",
            }}>Privacy Policy </Link></Typography>
        </Box>
      </Container>
    </form >
  );
};

export default FormComponent;
