import { configureStore } from '@reduxjs/toolkit';
import tokenReducer from './reducers/tokenReducer';
import objectReducer from './reducers/objectReducer';
import thunkMiddleware from 'redux-thunk';


const preloadedState = localStorage.getItem('reduxState')
  ? JSON.parse(localStorage.getItem('reduxState'))
  : {};

const store = configureStore({
  reducer: {
    token: tokenReducer,
    object: objectReducer,
  },
  middleware: [thunkMiddleware],
  preloadedState
});

store.subscribe(() => {
  localStorage.setItem('reduxState', JSON.stringify(store.getState()));
});

export default store;