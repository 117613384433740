import CSVUploadComponent from "../CSVUpload"

const CSVUpload = () => {
  return (
    <div>
      <CSVUploadComponent />
    </div>
  )
}

export default CSVUpload
