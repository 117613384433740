const styles = {
  container: {
    height: "100%",
    width: "100%",
  },
  image: (theme) => ({
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("lg")]: {

      width: "900px"
    },
    [theme.breakpoints.down("md")]: {
      width: "600px"
    },
  }),
  mainBannerImage: (theme) => ({
    width: "100%",
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  }),
}

export default styles
