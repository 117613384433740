import { Box } from '@mui/system';
import Footer from '../Footer';
import Header from '../Header';
import styles from './styles'
import ContextSection from './components/ContextSection/ContextSection';

// import Testimonials from './components/Testimonials/Testimonials';
import PricingDetailComponent from '../PricingDetail/PricingDetailComponent';
import { useEffect, useRef } from 'react';

const PricingComponent = () => {

  const myRef = useRef(null);

  useEffect(() => {
    if (myRef.current) {
      window.scrollTo({
        top: myRef.current.offsetTop,
        behavior: 'smooth',
      });
    }
  }, []);

  return (
    <Box sx={{ width: '100%', margin: 0 }}>
      <Box sx={styles.block}>
        <Header />
      </Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
      }}>
        <ContextSection />
      </Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
      }} ref={myRef}>
        <PricingDetailComponent />
      </Box>
      {/* <Box sx={styles.onlyForDesktopView}>
        <Testimonials />
      </Box> */}
      <Footer />
    </Box >
  );
};

export default PricingComponent;
