const styles = {
  container: (theme) => ({
    height: "100%",
    width: "50%",
    background: '#FFFFFF',
    margin: 'auto',
    padding: 5,
    [theme.breakpoints.down("md")]: {
    },
  }),
  mainHeading: (theme) => ({
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: '400 !important',
    fontSize: "40px !important",
    lineHeight: "48px !important",
    color: "#C85EF1",
    textAlign: 'center',
    marginBottom: "1%",
    [theme.breakpoints.down("md")]: {
      fontSize: "22px !important",
    },
  }),
  paragraph: (theme) => ({
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: '400 !important',
    fontSize: "18px !important",
    lineHeight: "32px !important",
    textAlign: "center",
    color: "#696969",
    [theme.breakpoints.down("md")]: {
      fontSize: "10px !important",
    },
  }),
  boxes: (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    margin: 'auto',
    gap: 10,
    width: "100%",
    padding: 1,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",

      justifyContent: 'center',
    },
  }
  ),
  subBoxes: (theme) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  }),
  subHeading: (theme) => ({
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: '200 !important',
    fontSize: "24px !important",
    lineHeight: "48px !important",
    color: "rgba(0, 0, 0, 0.9)",
    textAlign: 'center',
    marginBottom: "1%",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px !important",
    },
  }),
}

export default styles
