import AboutUsComponent from "../AboutUs"

const AboutUs = () => {
  return (

    <AboutUsComponent />
  )
}

export default AboutUs
