const contents = [
  {
    "item": "Introduce a new product or service",
    "id": 0
  },
  {
    "item": "Offer discounts on products or services",
    "id": 1
  },
  {
    "item": "Request feedback on customer experience",
    "id": 2
  },
  {
    "item": "Provide updates about upcoming promotions",
    "id": 3
  },
  {
    "item": "Request referrals from existing customers",
    "id": 4
  },
  {
    "item": "Follow up after an in-person meeting",
    "id": 5
  },
  {
    "item": "Ask for payment of overdue invoices",
    "id": 6
  },
  {
    "item": "Thank customers for their business",
    "id": 7
  },
  {
    "item": "Inviting customers to an event",
    "id": 8
  },
  {
    "item": "Inviting the recipient to an event or webinar",
    "id": 9
  },
  {
    "item": "Sending out newsletters with updates",
    "id": 10
  },
  {
    "item": "Asking for advice from industry leaders",
    "id": 11
  },
  {
    "item": "Seeking partnerships with other companies",
    "id": 12
  },
  {
    "item": "Following up after an in-person meeting",
    "id": 13
  },
  {
    "item": "Offering free resources or educational materials",
    "id": 14
  },
  {
    "item": "Offering a free trial or consultation",
    "id": 15
  },
  {
    "item": "Connecting with potential investors",
    "id": 16
  },
  {
    "item": "Scheduling a meeting or phone call",
    "id": 17
  },
  {
    "item": "Building brand awareness",
    "id": 18
  },
  {
    "item": "Generating leads",
    "id": 19
  },
  {
    "item": "Providing industry-related content or resources",
    "id": 20
  },
  {
    "item": "Following up on a previous conversation or meeting",
    "id": 21
  },
  {
    "item": "Requesting feedback or a testimonial",
    "id": 22
  },
  {
    "item": "Asking for a partnership or collaboration",
    "id": 23
  },
  {
    "item": "Updating the recipient on company news or developments",
    "id": 24
  },
  {
    "item": "Providing a case study or success story",
    "id": 25
  },
  {
    "item": "Offering exclusive access to a beta version of a product or service",
    "id": 26
  },
  {
    "item": "Providing a solution to a specific problem or need",
    "id": 27
  },
  {
    "item": "Requesting an appointment",
    "id": 28
  },
  {
    "item": "Offering a whitepaper or other educational resource",
    "id": 29
  },
  {
    "item": "Requesting a speaker for an event",
    "id": 30
  },
  {
    "item": "Offering a free consultation or assessment",
    "id": 31
  },
  {
    "item": "Requesting a quote or proposal",
    "id": 32
  },
  {
    "item": "Offering a free sample or demo",
    "id": 33
  },
  {
    "item": "Offering a referral program or incentive",
    "id": 34
  },
  {
    "item": "Providing a free trial or free test drive",
    "id": 35
  },
  {
    "item": "Offering a guarantee or money-back policy",
    "id": 36
  },
  {
    "item": "Providing a limited-time offer or sale",
    "id": 37
  },
  {
    "item": "Requesting a meeting or presentation",
    "id": 38
  },
  {
    "item": "Offering a product comparison or evaluation",
    "id": 39
  },
  {
    "item": "Offering a buy-one-get-one or other special deal",
    "id": 40
  },
  {
    "item": "Requesting a donation or sponsorship",
    "id": 41
  },
  {
    "item": "Offering an affiliate or reseller program",
    "id": 42
  },
  {
    "item": "Providing a checklist or guide",
    "id": 43
  },
  {
    "item": "Qualifying a potential customer",
    "id": 44
  },
  {
    "item": "Building a network or referral base",
    "id": 45
  },
  {
    "item": "Setting up a face-to-face meeting",
    "id": 46
  },
  {
    "item": "Building a sales pipeline",
    "id": 47
  },
  {
    "item": "Offering a limited-time offer",
    "id": 48
  },
  {
    "item": "Upselling to existing customers",
    "id": 49
  },
  {
    "item": "Qualifying leads for the sales team",
    "id": 50
  },
  {
    "item": "Exploring new market opportunities",
    "id": 51
  },
  {
    "item": "Offering a money-back guarantee",
    "id": 52
  },
  {
    "item": "Offering a referral program or incentive",
    "id": 53
  },
  {
    "item": "Providing a checklist or guide",
    "id": 54
  },
  {
    "item": "Offering a coupon or discount code",
    "id": 55
  },
  {
    "item": "Offering a partnership or collaboration opportunity",
    "id": 56
  },
  {
    "item": "Requesting a meeting or call to discuss potential business opportunities",
    "id": 57
  },
  {
    "item": "Presenting a proposal or quote for a project or service",
    "id": 58
  },
  {
    "item": "Providing industry-related content or resources",
    "id": 59
  },
  {
    "item": "Requesting a joint venture or strategic alliance",
    "id": 60
  },
  {
    "item": "Inviting the other company to join an affiliate or reseller program",
    "id": 61
  },
  {
    "item": "Offering a solution to a specific problem or need",
    "id": 62
  },
  {
    "item": "Making a job offer or inquiring about job opportunities",
    "id": 63
  },
  {
    "item": "Requesting a license or permission to use a product or service",
    "id": 64
  },
  {
    "item": "Making an offer or special deal",
    "id": 65
  },
  {
    "item": "To create a sense of urgency",
    "id": 66
  },
  {
    "item": "To increase brand loyalty",
    "id": 67
  },
  {
    "item": "To increase website traffic",
    "id": 68
  },
  {
    "item": "To increase social media engagement",
    "id": 69
  },
  {
    "item": "To test market demand for a product or service",
    "id": 70
  },
  {
    "item": "To capture early adopters",
    "id": 71
  },
  {
    "item": "To increase brand awareness",
    "id": 72
  },
  {
    "item": "To grow email list",
    "id": 73
  },
  {
    "item": "To increase sales",
    "id": 74
  },
  {
    "item": "To build a customer database",
    "id": 75
  },
  {
    "item": "To improve customer retention",
    "id": 76
  },
  {
    "item": "To gain market share",
    "id": 77
  },
  {
    "item": "To generate new revenue streams",
    "id": 78
  },
  {
    "item": "To expand into new markets",
    "id": 79
  },
  {
    "item": "To increase customer lifetime value",
    "id": 80
  },
  {
    "item": "To improve customer satisfaction",
    "id": 81
  },
  {
    "item": "To increase customer engagement",
    "id": 82
  },
  {
    "item": "To introduce new features or updates",
    "id": 83
  },
  {
    "item": "To offer a limited-time promotion or sale",
    "id": 84
  },
  {
    "item": "To gather customer feedback",
    "id": 85
  },
  {
    "item": "To offer a loyalty program",
    "id": 86
  },
  {
    "item": "To cross-sell or upsell products",
    "id": 87
  },
  {
    "item": "To offer a money-back guarantee",
    "id": 88
  },
  {
    "item": "To offer a free sample or trial",
    "id": 89
  },
  {
    "item": "To offer a free consultation or assessment",
    "id": 90
  },
  {
    "item": "To offer a free estimate or quote",
    "id": 91
  },
  {
    "item": "To offer a free webinar or workshop",
    "id": 92
  },
  {
    "item": "To offer a free e-book or whitepaper",
    "id": 93
  },
  {
    "item": "To offer a free app or tool",
    "id": 94
  },
  {
    "item": "To offer a free course or tutorial",
    "id": 95
  },
  {
    "item": "To offer a buy-one-get-one deal",
    "id": 96
  },
  {
    "item": "To offer a referral program",
    "id": 97
  },
  {
    "item": "To offer a discount or coupon code",
    "id": 98
  },
  {
    "item": "To offer an affiliate program",
    "id": 99
  }
]

export default contents