const styles = {
  container: (theme) => ({
    background: "radial-gradient(115.05% 275.33% at 3.47% 8.73%, #4D0775 0%, #251337 22.71%, #040013 47.19%, #1A0229 65.21%, #2A033F 82.41%)"/* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  }),
  inputs: (theme) => ({
    display: 'flex',
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 5,
    [theme.breakpoints.down("md")]: {
      '& input::placeholder': {
        fontSize: '12px',
        lineHeight: '12px',
      },
    },
  }),
  formContainer: (theme) => ({
    margin: "auto",
    padding: 16,
    [theme.breakpoints.down("md")]: {
      padding: 5,
    },
  }),
  textField: (theme) => ({
    width: '100%',
    '& input::placeholder': {
      fontSize: '20px',
      lineHeight: '20px',
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      '& input': {
        fontSize: '12px',
        lineHeight: '12px',
      },
      '& input::placeholder': {
        fontSize: '12px',
        lineHeight: '12px',
      },
    },
  }),

  h1: (theme) => ({
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "40px",
    lineHeight: "48px",
    color: "#C85EF1",
    flexDirection: "column",
    marginBottom: 2,
    [theme.breakpoints.down("md")]: {
      fontSize: '20px',
      fontWeight: "400",
      width: "140px",
      lineHeight: '20px !important',

    },
  }),
  containerBoxForForm: (theme) => ({
    padding: 5,
    margin: "auto",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      margin: 0,
      padding: 0,
      marginTop: 5
    },
  }
  ),
  Button: (theme) => ({
    height: "53px",
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "19px",
    width: "250px",
    color: "#fff",
    background: "linear-gradient(266.85deg, #005CF3 -16.58%, rgba(255, 0, 199, 0.52) 179.36%)",
    borderRadius: "26.5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "100px",
      height: "40px",
      fontSize: "12px",
    },
  })
};

export default styles
