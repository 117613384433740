import { Box } from '@mui/system';
import { useState } from 'react';
import HeaderAfterLogin from '../HeaderAfterLogin'
import CardComponent from './component/Card/Card';

const DashboardComponent = () => {
  const [open, setOpen] = useState(true)
  const [mobileView, setMobileView] = useState(false)
  return (
    <Box sx={{ width: '100%' }}>
      <HeaderAfterLogin
        Title={"Dashboard"}
        open={open}
        setOpen={setOpen}
        setMobileView={setMobileView}
        mobileView={mobileView}
        body={<CardComponent />}
      />
    </Box >
  );
};

export default DashboardComponent;

