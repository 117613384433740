const styles = {
  container: (theme) => ({
    background: '#FFFFFF',
    height: "700px",
    [theme.breakpoints.down("md")]: {
      padding: 0,
      margin: 0
    },
  }),
  mainHeading: (theme) => ({
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: '400 !important',
    fontSize: "50px !important",
    lineHeight: "48px !important",
    color: "#C85EF1",
    textAlign: 'center',
    padding: 30,
    [theme.breakpoints.down("md")]: {

      fontSize: "30px !important",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      paddingTop: 40,
      fontSize: "30px !important",
    },
  }),


}

export default styles
