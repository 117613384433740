// import { Container, Typography } from "@mui/material"
import DashboardComponent from "../Dashboard"

const Dashboard = () => {
  return (
    <DashboardComponent />
  )
}

export default Dashboard
