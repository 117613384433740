// import { Container, Typography } from "@mui/material"
import ColdEmailComponent from "../ColdEmail"


const ColdEmail = () => {
  return <div>
    <ColdEmailComponent />
  </div>
}

export default ColdEmail
