const styles = {

  textField: (theme) => ({
    color: "rgba(0, 0, 0, 0.4)",
    width: '100%',
    '& input::placeholder': {
      fontSize: '16px',
      lineHeight: '16px',
      background: "rgba(222, 222, 222, 0.4)",
    },
    '& input': {
      fontSize: '16px',
      lineHeight: '16px',

    },
    [theme.breakpoints.down("md")]: { width: "100%" },
  }),
  description: (theme) => ({
    color: "#000000",
    width: '100%',
    background: "rgba(222, 222, 222, 0.4)",
    '& input::placeholder': {
      fontSize: '16px',
      lineHeight: '16px',
    },
    '& input': {
      fontSize: '16px',
      lineHeight: '16px'
    }
  }),
  button: (theme) => ({
    width: '100%',
    fontFamily: 'Inter',
    fontStyle: "bold",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "19px",
    color: "#fff",
    padding: 3,
    background: "linear-gradient(266.85deg, #005CF3 -16.58%, rgba(255, 0, 199, 0.52) 179.36%)",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
      lineHeight: "12px",
    },
  })
};

export default styles
