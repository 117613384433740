import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Button, Stack } from '@mui/material';
import logo_desk from "../../assets/img/logo_desc.svg";
import logo_mob from "../../assets/img/logo_mob.svg";
import styles from './styles';

import create from '../../assets/dashboard/create.png';
import home from '../../assets/dashboard/home.png';
import templates from '../../assets/dashboard/templates.png';
import bulkImport from '../../assets/dashboard/import.png';

import { useEffect, useState } from 'react';
import axios from 'axios';
import CONFIG from '../config';
import AccountMenu from '../Helper/BasicMenu';
import { useNavigate } from "react-router-dom";
import LoadingSpinner from '../Loader/loadingSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { clearToken } from '../../redux/action';


const Images = [create, home, templates, bulkImport]
const drawerWidth = 240;

const drawer = (
  <div>
    <Stack rowGap={5}>
      <Toolbar disableGutters sx={{ margin: 'auto', padding: 2 }}>
        <Typography component='a' sx={styles.logoDesk} href='/'>
          <img src={logo_desk} alt='logo' />
        </Typography>
        <Typography component='a' sx={styles.logoMob} href='/'>
          <img src={logo_mob} alt='logo' />
        </Typography>
      </Toolbar>
      <List sx={{ color: "#FFFFFF" }}>
        {[
          { text: 'Home', value: 0, href: "/" },
          { text: "Create New", value: 1, href: "/dashboard" },
          { text: 'Templates', value: 2, href: "/dashboard" },
          { text: 'Bulk Import', value: 3, href: "/csvupload" }].map((item, index) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton href={item.href} sx={{
                "&:hover": {
                  opacity: 0.5
                },
              }} >
                <ListItemIcon >
                  <img src={Images[item.value]} alt={item.text} />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{
                  style: {
                    color: '#FFFFFF', fontSize: "20px", "&:hover": {
                      color: "#000000"
                    },
                  }
                }} primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
      }}>
        <Button sx={styles.buttonFeilds} href="/subscription">Go Premium</Button>
      </Box>
    </Stack>
  </div>
);

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    // flexGrow: 1,
    padding: theme.spacing(2),
    width: "60%",
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
    [theme.breakpoints.down("md")]: {
      width: "100%",
      flexGrow: 0,
      padding: 0,
    },
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  background: "linear-gradient(85.78deg, #1D09BD -23.77%, #EB01E3 110.4%)",
  [theme.breakpoints.down("md")]: {
    width: "100%"
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function HeaderAfterLogin({ Title, open, setOpen, body, ImageLink, mobileView, setMobileView }) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();

  const [userDetail, setUserDetail] = useState({
    name: "",
    email: "",
  })
  const [plan, setPlan] = useState("")

  useEffect(() => {
    getUserDetail()
    getUserPlan()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getUserPlan = async () => {
    setIsLoading(true)
    await axios.get(CONFIG.BASE_URL + "api/payments/user-current-plan", {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
      .then((response) => {
        setIsLoading(false)
        if (response.data.status_code === 200) {
          localStorage.setItem("userPlan", response.data.data.current_plan)
          setPlan(response.data.data.current_plan)
        }
      }).catch((error) => {
        setIsLoading(false)
      })
  }
  const getUserDetail = async () => {
    setIsLoading(true)
    await axios.post(CONFIG.BASE_URL + "api/registration/user-detail/", {}, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
      .then((response) => {
        setUserDetail({ ...userDetail, email: response.data.email, name: response.data.first_name + " " + response.data.last_name })
        setIsLoading(false)
      }).catch((error) => {
        setIsLoading(false)
        if (error.response.data.code === "token_not_valid") {
          dispatch(clearToken())
          navigate('/login')
        }
      })
  }

  const handleDrawerOpen = () => {
    setMobileView(true);
  };
  const handleDrawerClose = () => {
    setMobileView(false)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Stack direction={"row"} justifyContent={"space-between"} >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, display: { sm: 'flex', md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h4" noWrap component="div" sx={styles.title}>
              {Title}
            </Typography>
          </Toolbar>
          {isLoading ? <LoadingSpinner /> : <AccountMenu ImageLink={ImageLink} name={userDetail.name} email={userDetail.email} plan={plan} />}
        </Stack>
      </AppBar >
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: "#0C2632"
          },
          display: { xs: 'none', sm: 'none', md: 'flex' }
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        {drawer}
      </Drawer>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: "#0C2632"
          },
          display: { xs: 'flex', sm: 'flex', md: 'none' }
        }}
        variant="temporary"
        anchor="left"
        open={mobileView}
        onClose={handleDrawerClose}
      >
        {drawer}
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {body}
      </Main>
    </Box >
  );
}