import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import styles from './styles'

// import Heading1 from "../../../../assets/pricing/1stHeading.svg"
// import Heading2 from "../../../../assets/pricing/2ndHeading.svg"
// import Heading3 from "../../../../assets/pricing/3rdHeading.svg"


const ContextSection = () => {
  return (
    <Box sx={styles.container} >
      <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginBottom: 5, width: "100%" }}>
        <Typography sx={styles.mainHeading}>Get started with Closed Won today</Typography>
        <Typography sx={styles.paragraph}>
          Welcome! We offer three pricing plans to suit your needs: free, basic, and pro. Our free plan is perfect for getting started and trying out our tool today. With our basic plan, you get access to additional features like the CSV bulk import and increased usage limits. And with our pro plan, you'll get even more advanced features like the LinkedIn Prospecting Assistant and priority support. Choose the plan that works best for you and get started today!</Typography>
      </Box>
      {/* <Box sx={styles.boxes}>
        <Stack sx={styles.subBoxes}>
          <Typography component='a' sx={{ display: "flex", justifyContent: "center" }}>
            <img src={Heading1} alt='Context' />
          </Typography>
          <Typography component='a' sx={{ display: "flex", justifyContent: "center", flexDirection: "column", marginTop: 5 }}>
            <Typography sx={styles.subHeading}>3rd Heading Here</Typography>
            <Typography sx={styles.paragraph}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud</Typography>
          </Typography>
        </Stack>
        <Stack sx={styles.subBoxes}>
          <Typography component='a' sx={{ display: "flex", justifyContent: "center" }}>
            <img src={Heading2} alt='Context' />
          </Typography>
          <Typography component='a' sx={{ display: "flex", justifyContent: "center", flexDirection: "column", marginTop: 5 }}>
            <Typography sx={styles.subHeading}>3rd Heading Here</Typography>
            <Typography sx={styles.paragraph}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud</Typography>
          </Typography>
        </Stack>
        <Stack sx={styles.subBoxes}>
          <Typography component='a' sx={{ display: "flex", justifyContent: "center" }}>
            <img src={Heading3} alt='Context' />
          </Typography>
          <Typography component='a' sx={{ display: "flex", justifyContent: "center", flexDirection: "column", marginTop: 5 }}>
            <Typography sx={styles.subHeading}>3rd Heading Here</Typography>
            <Typography sx={styles.paragraph}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud</Typography>
          </Typography>
        </Stack>
      </Box> */}
      <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: 5 }}>
        <Typography sx={styles.mainHeading}>Compare Our Plans</Typography>
      </Box>
    </Box >
  );
};

export default ContextSection;
