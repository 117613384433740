import { Button, Typography, Stack } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import styles from './styles';
import googlelogo from '../../assets/img/google_log.svg'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setObject, setToken } from '../../redux/action';
import CONFIG from '../config';

function Googlelogin({ text }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState(false)
  const GoogleLogin = useGoogleLogin({
    onSuccess: async response => {
      setErrors(false)
      try {
        const res = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: {
            "Authorization": `Bearer ${response.access_token}`
          }
        })
        setErrors(false)
        await login(response.access_token, res)
      } catch (err) {
        console.log(err)
        setErrors(true)
      }
    }
  });

  let login = async (response, res) => {
    var formdata = new FormData();
    formdata.append("authToken", response);
    formdata.append("email", res.data.email);
    formdata.append("id", res.data.sub);
    formdata.append("name", res.data.name);
    formdata.append("photoUrl", res.data.picture);
    formdata.append("provider", "GOOGLE");

    var config = {
      method: 'post',
      url: CONFIG.BASE_URL + "api/registration/google-login/",
      data: formdata
    };

    axios(config)
      .then(function (response) {
        dispatch(setToken(response.data.tokens.access));
        dispatch(setObject({ emailVerified: true }))
        navigate('/dashboard')
      })
      .catch(function (error) {
        setErrors(true)
      });
  }
  return <Stack display={"flex"} justifyContent={"center"} flexDirection={"column"}>
    <Button onClick={GoogleLogin} variant="outlined" sx={styles.googleButton}>
      <img src={googlelogo} alt="Google logo" style={{ height: "30px" }} />
      <Typography sx={styles.text}>   {text}</Typography>
    </Button>
    {errors ? <div style={{ color: 'red', marginBottom: 15 }}><p>Something went wrong. Please try!</p></div> : null}
  </Stack>
}
export default Googlelogin