import BtnNav from "./BtnNav"
import Box from '@mui/material/Box'
import styles from "./styles"

const pages = [
  { title: "Use Cases", arrow: false, link: "/" },
  { title: "Pricing", arrow: false, link: "/pricing" },
  { title: "Blog", arrow: false, link: "/blog" },
  { title: "Company", arrow: false, link: "/aboutus" }
]


const Navbar = () => {
  return (
    <Box
      sx={styles.navBar}
    >
      {
        pages.map((page, index) => (
          <BtnNav page={page} key={index} />
        ))
      }
    </Box>
  )
}

export default Navbar

