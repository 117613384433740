const styles = {
  navBar: (theme) => ({
    display: "contents",
    alignItems: "center",
    marginLeft: "70px",
    [theme.breakpoints.down("md")]: {
      display: "none"
    },
  }),
  btnLink: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "20px",
    display: "flex",
    color: "#fff",
    marginRight: "25px",
    marginBottom: "10px",
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      color: "#686868"
    },
    // height: "18.35px",
    // width: "63px"
  },
  drawerButtonLink: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "20px",
    padding: 0,
    display: "flex",
    color: "#000000",
    marginRight: "44px",
    marginBottom: "10px",
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      color: "#686868"
    },
    width: "68.25px",
    height: "18.35px",
    left: "1067.85px",
    top: "52.72px"
  }
}

export default styles
