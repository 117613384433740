import { Box } from '@mui/system';
import { useState } from 'react';
import HeaderAfterLogin from '../HeaderAfterLogin';
import MainComponent from './component/MainComponent';

const SubscirptionComponent = () => {
  const [open, setOpen] = useState(true)
  const [mobileView, setMobileView] = useState(false)

  return (
    <Box sx={{ width: '100%' }}>
      <HeaderAfterLogin
        Title={"Subscription"}
        open={open}
        setOpen={setOpen}
        setMobileView={setMobileView}
        mobileView={mobileView}
        body={<MainComponent />}
      />
    </Box >
  );
};

export default SubscirptionComponent;

