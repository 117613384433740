const styles = {
  container: (theme) => ({
    background: "radial-gradient(115.05% 275.33% at 3.47% 8.73%, #4D0775 0%, #251337 22.71%, #040013 47.19%, #1A0229 65.21%, #2A033F 82.41%)"/* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    [theme.breakpoints.down("md")]: {
    },
  }),

  h1: (theme) => ({
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "80px !important",
    lineHeight: "48px",
    color: "#C85EF1",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    marginBottom: 10,
    [theme.breakpoints.down("md")]: {
      fontSize: '25px !important',
      fontWeight: "400",
      lineHeight: '20px !important',
      marginBottom: 5,
    },
  }),
  paragraph: (theme) => ({
    "fontFamily": "'Roboto'",
    "fontStyle": "normal",
    "fontWeight": "400",
    "fontSize": "100px",
    "lineHeight": "32px",
    "color": "#696969",
    "height": "65px",
    "textAlign": "center",
    [theme.breakpoints.down("md")]: {
      fontSize: '25px',
      fontWeight: "400",
      lineHeight: '20px !important',
      marginBottom: 2
    },
  }),
  containers: (theme) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: 'auto',
    padding: 25,
    [theme.breakpoints.down("md")]: {
      fontSize: '25px',
      fontWeight: "400",
      lineHeight: '20px !important',
      padding: 5,
      height: 400
    },
  }),

};

export default styles
