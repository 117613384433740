const styles = {
  block: (theme) => ({
    width: "100%",
    height: "100%",
    alignItems: "center",
    background: "radial-gradient(115.05% 275.33% at 3.47% 8.73%, #4D0775 0%, #251337 22.71%, #040013 47.19%, #1A0229 65.21%, #2A033F 82.41%)", /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    [theme.breakpoints.down("md")]: {
      height: "100%"
    }
  }),
  buttons: (theme) => ({
    flexDirection: "row",
    display: "flex",
    gap: 2,
    marginLeft: "20px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      // padding: "5px",
      flexDirection: "column",
      gap: 2,
      marginTop: "5px",
      justifyContent: 'center'
    },
  }),
  buttonFeilds: (theme) => ({
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "20px",
    textAlign: "center",
    background: "#FFFFFF",
    borderRadius: "8px",
    border: 1,
    borderColor: "#fff",
    color: "#000000",
    width: "136.5px",
    height: "38.53px",
    textTransform: "none", marginTop: "20px",
    "&:hover": {
      color: "#FFFFFF",
      borderColor: "#000000",
      border: 1
    },
    [theme.breakpoints.down("md")]: {

      width: "136.5px",
      height: "38.53px",
    },
  }),
  mainBanner: (theme) => ({
    display: 'flex',
    flexDirection: 'row',
    marginTop: "50px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      flexDirection: 'column',
      justifyContent: 'center'
    },
  }),
  mainBannerItem: (theme) => ({
    width: "50%",
    margin: "auto",
    [theme.breakpoints.down("lg")]: {
      width: "70%",
      padding: 5
    },
  }),
  mainBannerImage: (theme) => ({
    width: "75%",
    background: 'none',
    padding: 5,
    margin: 'auto',
    [theme.breakpoints.down("md")]: {
    },
  }),
  mainBannerHeading: (theme) => ({
    fontSize: "55px !important",
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: '900 !important',
    alignItems: "center",
    color: "#FFFFFF",
    wordWrap: 'break-word',
    // wordSpacing: 10,
    lineHeight: 'inherit !important',
    [theme.breakpoints.down("md")]: {
      fontSize: "24px !important"
    },
  }),
  onlyForDesktopView: (theme) => ({
    [theme.breakpoints.down("sm")]: {
      display: 'none'
    },
  }),
}

export default styles
