
const styles = {
  paragraph: (theme) => ({
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: '400 !important',
    fontSize: "14px !important",
    color: "#000000",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      fontSize: "10px !important",
    },
  }),
  boxes: (theme) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    margin: 'auto',
    gap: 5,
    padding: 1,
    width: "max-content",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      justifyContent: 'center',
      width: "100%"
    },
  }
  ),
  subBoxes: (theme) => ({
    border: "0.734472px solid #9804D3",
    margin: "auto",
    padding: 5,
    height: 350,
    width: "max-content",
    [theme.breakpoints.down("md")]: {
      height: "100%"
    },
  }),
  subHeading: (theme) => ({
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: '200 !important',
    fontSize: "20px !important",
    color: "rgba(0, 0, 0, 0.9)",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px !important",
    },
  }),
  price: (theme) => ({
    color: "#9804D3",
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "50px !important",
    lineHeight: "97px",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px !important",
      lineHeight: "10px",
    },
  }),
  Button: (theme) => ({
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    color: "#9804D3",
    border: "1.47253px solid #9804D3",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
  }),
  selectedSubHeading: (theme) => ({
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: '200 !important',
    fontSize: "24px !important",
    color: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px !important",
    },
  }),
  selectedPrice: (theme) => ({
    color: "#FFFFFF",
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "50px !important",
    lineHeight: "97px",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px !important",
      lineHeight: "10px",
    },
  }),
  selectedParagraph: (theme) => ({
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: '400 !important',
    fontSize: "14px !important",
    color: "#FFFFFF",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      fontSize: "10px !important",
    },
  }),
  notInOffer: (theme) => ({
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: '400 !important',
    fontSize: "14px !important",
    color: "#000000",
    width: "100%",
    textDecoration: "line-through",
    [theme.breakpoints.down("md")]: {
      fontSize: "10px !important",
    },
  }),
  selectedNotInOffer: (theme) => ({
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: '400 !important',
    fontSize: "14px !important",
    color: "#FFFFFF",
    width: "100%",
    textDecoration: "line-through",
    [theme.breakpoints.down("md")]: {
      fontSize: "10px !important",
    },

  }),
  selectedSubBoxes: (theme) => ({
    border: "0.734472px solid #9804D3",
    background: "#9804D3",
    margin: "auto",
    padding: 5,
    // height: 350,
    width: "max-content",
    [theme.breakpoints.down("md")]: {
      height: "100%"
    },
  }),
}

export default styles
