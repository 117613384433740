import { CardMedia } from '@mui/material';
import { Box } from '@mui/system';
import styles from './styles'
import Image from '../../../../assets/howitsWorks.svg'
const HowItsWork = () => {
  return (
    <Box sx={styles.container} >
      <CardMedia
        component="img"
        image={Image}
        alt="Context Image 1"
        sx={styles.mainBannerImage}
      />
      {/* <Typography component='a' display={"flex"} justifyContent={"center"} sx={styles.image}>
        <img src={Image} alt='HowItsWork' />
      </Typography> */}
    </Box >
  );
};

export default HowItsWork;
