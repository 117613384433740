const styles = {
  container: (theme) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "auto",
    padding: 4,
    border: "0.734472px solid #9804D3",
    width: "100%",
    marginLeft: 30,
    borderStyle: "linear-gradient(266.85deg, #005CF3 -16.58%, rgba(255, 0, 199, 0.52) 179.36%)",
    [theme.breakpoints.down("lg")]: {
      paddingTop: 5,
      margin: "auto",

    },
  }),
  h1: (theme) => ({
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600 !important",
    fontSize: "24px !important",
    lineHeight: "39px",
    color: "#C85EF1",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px !important",
      fontWeight: "400 !important",
      display: 'none'
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px !important",
      fontWeight: "200 !important",
      display: 'none'
    }
  }),
  text: (theme) => ({
    [theme.breakpoints.up("md")]: {
      display: 'none'
    },
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "39px",
    color: "#C85EF1",
    justifyContent: "center",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px !important",
      fontWeight: "200 !important",
    }
  }),
  subContainer: (theme) => ({
    width: "40%",
    display: "flex", justifyContent: "center", flexDirection: "column", paddingTop: 2,
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      paddingTop: 2
    },
  }),
  Button: (theme) => ({
    height: "53px",
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "19px",
    width: "250px",
    color: "#fff",
    background: "linear-gradient(266.85deg, #005CF3 -16.58%, rgba(255, 0, 199, 0.52) 179.36%)",
    borderRadius: "26.5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "100px",
      height: "40px",
      fontSize: "10px",
    },
  })
}

export default styles
