import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import styles from './styles'

const ContextSection = () => {
  return (
    <Box sx={styles.container} >
      <Typography sx={styles.mainHeading}>Coming Soon ...</Typography>
    </Box >
  );
};

export default ContextSection;
